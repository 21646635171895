import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { configJson } from "../../../Constent/config";
import { toast } from "react-toastify";
import { updatePatientdetails } from "../../../redux/slices/patientsSlice";
import { useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";

const USpatientEdit = ({ show, handleClose, patientID }) => {
  const [formData, setFormData] = useState({});
    const [users, setUsers] = useState([]); // Replacing tokens with users from backend
  const dispatch = useDispatch();
    const navigate = useNavigate();
    // Extract the patient_id from the URL query string
const queryParams = new URLSearchParams(window.location.search);
const patientIdFromURL = queryParams.get("id");
  const getPatientById = async (id) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/getpatient/${patientID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Parse the JSON response
      const data = await response.json();

      if (response.ok) {
        console.log("Patient data fetched successfully:", data.patient);
        setFormData(data.patient);
      } else {
        console.error("Error:", data.message);
        toast.error(data.message);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error.message);
      toast.error("An unexpected error occurred: " + error.message);
    }
  };

  // const fetchTokenForPatient = async () => {
  //   try {
  //     const formattedDate = new Date().toISOString().split("T")[0]; // Current date
  //     const response = await fetch(
  //       `${configJson.backend_URL}/interaction/token/${doctorID}/99/${formattedDate}`
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       // Find the token number for the specific patient
  //       const patientInteraction = data.interaction.find(
  //         (user) => user.patient_id === patientID
  //       );

  //       if (patientInteraction) {
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           token_number: patientInteraction.token_number,
  //         }));
  //       }
  //     } else {
  //       throw new Error("Failed to fetch token number");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching token:", error.message);
  //   }
  // };


  useEffect(() => {
    if (show && patientID) {
      getPatientById(patientID);
      // fetchTokenForPatient();
    }
  }, [show, patientID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const updatedData = {
      ...formData,
    };

    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/updatepatientlist/${patientID}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("orgtoken")}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        const result = await response.json();
        toast.error(result.message);

        
      } else {
         
        const result = await response.json();
        console.log(result)
        const disData={
            patient_id: result?.patient?.patient_id,
            Patient: {
              first_name: result?.patient?.first_name,
              last_name: result?.patient?.last_name,
              date_of_birth: result?.patient?.date_of_birth,
            },
          }
          console.log(disData,"disData");
          dispatch(updatePatientdetails(disData))
          console.log("windowpath", patientIdFromURL === result?.patient?.patient_id, patientIdFromURL, result?.patient?.patient_id);

          // Compare the extracted patient_id with the one in the result
          if (patientIdFromURL === result?.patient?.patient_id) {
            console.log("fasd");
            navigate(`/activity?id=${result?.patient?.patient_id}`);
          }
      }
    } catch (error) {
      console.error("Error updating patient:", error);
    }

    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formPatientID">
                <Form.Label>Patient ID</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.patient_id}
                  name="patient_id"
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.first_name}
                  name="first_name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.last_name}
                  name="last_name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formDOB">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.date_of_birth}
                  name="date_of_birth"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.phone_number}
                  name="phone_number"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formInsuranceNo">
                <Form.Label>Insurance No</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.insurance_no}
                  name="insurance_no"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formAddress1">
                <Form.Label>Address 1</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.address1}
                  name="address1"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formAddress2">
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.address2}
                  name="address2"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formState">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.state}
                  name="state"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.city}
                  name="city"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formZipCode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.zip_code}
                  name="zip_code"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default USpatientEdit;
