import React from "react";
import { Table, Button } from "react-bootstrap";

const TemplateAfterUpdate = ({
  prescriptionDataTemplate,
  handleTemplateUpdateRow,
  handleTemplateDeleteRow,
  handleAddRowTemplate,
}) => {
  return (
    <Table bordered responsive className="medicinetable">
      <thead>
        <tr>
          <th
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
              width: "40%", // Adjusted width for Medicine Name
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span style={{ width: "100%", textAlign: "left" }}>
                Medicine Name(Dosage Type)
              </span>
            </div>
          </th>
          <th
            style={{
              width: "10%", // Reduced width for FN
              textAlign: "left",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            frequency
          </th>
          {/* <th
            style={{
              width: "10%", // Reduced width for AN
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            AN
          </th>
          <th
            style={{
              width: "10%", // Reduced width for N
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            N
          </th> */}
          <th
            style={{
              width: "10%",
              textAlign: "left",
              fontSize: "15px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Route
          </th>
          <th
            style={{
              width: "15%", // Adjusted width for Days
              textAlign: "left",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Period
          </th>
          <th
            className="printlastnonecol"
            style={{
              width: "40%", // Adjusted width for Instructions
              textAlign: "left",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Remarkes
          </th>
        </tr>
      </thead>
      <tbody className="templateaftereditdata">
        {prescriptionDataTemplate.map((row) => (
          <tr key={row.id}>
            <td style={{ width: "35%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                >
                  {row.medication_name}({row?.dosage} {row?.medication_type})
                </span>
                {/* <span
                  style={{
                    width: "21%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                >
                  {row.dosage}
                </span> */}
                {/* <span
                  style={{
                    width: "21%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                >
                  {row?.medication_type}
                </span> */}
              </div>
            </td>
            <td style={{ width: "10%", textAlign: "left" }}>
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {parseFloat(row.frequency.morning) % 1 === 0
                  ? row.frequency.morning.toString().split('.').shift()
                  : row.frequency.morning}
              </span>
              -{/* </td> */}
              {/* <td style={{ width: "100%", textAlign: "center" }}> */}
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {parseFloat(row.frequency.afternoon) % 1 === 0
                  ?  row.frequency.afternoon.toString().split('.').shift()
                  : row.frequency.afternoon}
              </span>
              -{/* </td> */}
              {/* <td style={{ width: "10%", textAlign: "center" }}> */}
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {parseFloat(row.frequency.night) % 1 === 0
                  ?  row.frequency.night.toString().split('.').shift()
                  : row.frequency.night}
              </span>
            </td>
            <td style={{ width: "15%", textAlign: "left" }}>
              <span
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row?.route}
              </span>
            </td>
            <td style={{ width: "15%", textAlign: "left" }}>
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.duration}
              </span>
            </td>
            <td className="printlastnonecol" style={{ width: "40%",textAlign: "left" }}>
              <span
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.instructions}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TemplateAfterUpdate;
