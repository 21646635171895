import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { configJson } from "../../../../Constent/config";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { message, Input, Image } from "antd";
import Webcam from "react-webcam";
import axios from "axios";
import { convertToKolkataTime, convertToUTC } from "../../../../helpers";
import { json } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addPatientToTop,
  updatePatient,
  updatePatientStatus,
  addPatientToList,
} from "../../../../redux/slices/patientsSlice";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const UploadFiles = ({
  show,
  onClose,
  patientId,
  doctorId,
  setUploadModal,
  fetchInteractions,
  userDetails,
}) => {
  const [interactionTypes, setInteractionTypes] = useState([]);
  const [doctorUploadNotes, setDoctorUploadNotes] = useState("");
  const [interactionDetailType, setInteractionDetailType] = useState("mri");
  const [uploadStatusMessage, setUploadStatusMessage] = useState("");
  const [showUploadStatusPopup, setShowUploadStatusPopup] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedInteractionTypeId, setSelectedInteractionTypeId] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [openCam, setOpenCam] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  // const [medicines, setMedicines] = useState([]);
  // const [otherMedicines, setOtherMedicines] = useState([]);
  const [isMedicine, setIsMedicine] = useState(false);
  const [interactionId, setInteractionId] = useState();
  // const [formData, setFormData] = useState({
  //   patient_id: userDetails?.patientId,
  //   doctor_id: parseFloat(userDetails?.doctorId),
  //   interaction_date:  new Date().toISOString().slice(0, 19).replace('T', ' '),
  //   interaction_type: "Upload",
  //   interaction_status: '',
  //   interaction_notes: "",
  //   interaction_detail_type: "",
  // });
  const [decode, setDecode] = useState({});
  const navigate = useNavigate();
  console.log(file, "File");
  const interactionIdFirst = localStorage.getItem("interactionIdFirst")
    ? localStorage.getItem("interactionIdFirst")
    : null;

  const cameraRef = useRef(null);
  const [medicineData, setMedicineData] = useState({
    medicines: [],
    otherMedicines: [],
  });

  const dispatch = useDispatch();

  const handleCapture = () => {
    if (file !== null) {
      setFile(null);
    }

    const screenshot = cameraRef?.current?.getScreenshot();

    if (screenshot) {
      // Convert base64 data URL to a Blob
      const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(base64.split(",")[1]);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      };

      // Convert base64 to Blob
      const blob = base64ToBlob(screenshot, "image/jpeg");

      // Create a File object with the new name
      const fileName = `${new Date().toISOString()}.jpg`;
      const renamedFile = new File([blob], fileName, { type: "image/jpeg" });
      // Set the renamed file
      setFile([renamedFile]);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      // Decode token and update state
      const decoded = jwtDecode(token);
      setDecode(decoded);
    } catch (error) {
      console.error("Error decoding token:", error);
      navigate("/login");
    }
  }, [navigate, patientId]);

  const handleCancel = () => {
    onClose();
    setInteractionDetailType("");
    setFile(null);
    setOpenCam(false);
    setDoctorUploadNotes("");
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 0) {
      setFile(selectedFiles);
      console.log(
        `Files selected: ${selectedFiles.map((file) => file.name).join(", ")}`
      );
    }
  };

  function getFormattedDateTimeUTC() {
    const dateTime = new Date();
    const formattedDate = convertToUTC();
    const d = convertToKolkataTime(formattedDate);
    const timee = d.replace(/[-:]/g, "_").replace(", ", "_");

    const formattedDateTime = [
      String(dateTime.getUTCMonth() + 1).padStart(2, "0"), // Month
      String(dateTime.getUTCDate()).padStart(2, "0"), // Day
      String(dateTime.getUTCFullYear()).slice(-2), // Year
      String(dateTime.getUTCHours()).padStart(2, "0"), // Hours
      String(dateTime.getUTCMinutes()).padStart(2, "0"), // Minutes
      String(dateTime.getUTCSeconds()).padStart(2, "0"), // Seconds
    ].join("_");

    return timee;
  }

  const handleUploadStatusPopup = () => {
    setShowUploadStatusPopup(false);
  };

  const handleSwitchCamera = () => {
    setIsFrontCamera((prev) => !prev);
  };

  // console.log(file, 'UYTRDX')


  const handleSubmitDocs = async () => {
    for (let i = 0; i < file.length; i++) {
      const files = file[i];
      if (files.name.endsWith('.docx' || '.heic' || '.HEIC')) {
        // console.log("File with '.docx' extension found. Returning null.");
        return message.error('Docx, HEIC file formats are not supported')
      }
    }
    setLoading(true);
    if (file?.length === 0) {
      message.error("Please upload at least one file");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    const dateTime = getFormattedDateTimeUTC();

    formData.append("patient_id", patientId);
    formData.append("doctor_id", decode?.doctor_id ? decode?.doctor_id : null);
    formData.append(
      "interaction_date",
      new Date().toISOString().slice(0, 19).replace("T", " ")
    );
    formData.append("interaction_type", "docs");
    formData.append("interaction_status", "");

    file?.forEach((file, index) => {
      const fileExtension = file.name.split(".").pop();
      const newFileName = `files/${patientId}_${dateTime}_${index}.${fileExtension}`;

      const newFile = new File([file], newFileName, { type: file.type });

      formData.append("file", newFile);
    });

    try {
      const res = await axios.post(
        `${configJson.backend_URL}/interaction/createImg`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.data?.data?.isMedicine) {
        setIsMedicine(true);
        setMedicineData(res?.data?.data?.answer[0]);
        setInteractionId(res?.data?.interaction_id);
        setLoading(false);
      }
      if (fetchInteractions && !res?.data?.data?.isMedicine) {
        fetchInteractions(patientId);
      }
      // if (!res?.data?.data?.isMedicine) {
      //   setUploadModal(false);
      // }
    } catch (error) {
      console.error("Error uploading interaction:", error);
      setUploadModal(false);
      setLoading(false);
      // if (fetchInteractions) {
      //   fetchInteractions(patientId);
      // }
    }
    // finally {
    //   setLoading(false);
    //   setUploadModal(false);
    //   setIsMedicine(false);
    //   setMedicineData({
    //     medicines: [],
    //     otherMedicines: [],
    //   })
    //   if (fetchInteractions) {
    //     fetchInteractions(patientId);
    //   }
    // }
  };

  // const displayImage = () => {
  //   if (!file) return null; // Handle case where no image is selected

  //   // Handle different image loading scenarios:
  //   if (file instanceof File) {
  //     // Assuming you're using FileReader:
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setFile(e.target.result); // Update state with image data URL
  //     };
  //     reader.readAsDataURL(file);
  //   } else if (typeof file === 'string') {
  //     // If file is a URL:
  //     return <img src={file} alt="" width={200} className="m-auto" />;
  //   } else {
  //     console.error('Unexpected file format');
  //     return null; // Handle unexpected format
  //   }
  // }

  const gsLink = Object.keys(medicineData)[0];

  const handleInputChange = (e, index, row, field) => {
    const updatedData = { ...medicineData };
    updatedData[gsLink][row][index][field] = e.target.value;
    setMedicineData(updatedData);
  };

  const handleMedicines = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/medicines/${patientId}/${interactionId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(medicineData),
        }
      );
      if (response.ok) {
        // console.log("File sent successfully!");
        setIsMedicine(false);
      } else {
        console.error("Failed to send file:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setLoading(false);
      setUploadModal(false);
      setIsMedicine(false);
      if (fetchInteractions) {
        fetchInteractions(patientId);
      }
      setMedicineData({ medicines: [], otherMedicines: [] });
    }
  };

  async function updatedata(url) {
    // console.log("KKk");
    const mydata = {
      patient_id: patientId,
      doctor_id: decode?.doctor_id,
      interaction_date: Date.now(),
      interaction_id: interactionIdFirst,
      organization_id: userDetails.orgId,
      audioUrl: url,
      // types: decode.role,
    };
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/feedRecorder`,
        mydata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.data.status === true) {
        // console.log("Audio upload success full");
        dispatch(addPatientToList(response?.data?.result));
        const changeStatus_color = {
          patient_id: response?.data?.result?.patient_id,
          interaction_status: "0",
        };
        dispatch(updatePatientStatus(changeStatus_color));
        checkInteractionStatus(response?.data?.result?.interaction_id);
      }
    } catch (error) {
      console.error("Error:", error);
      setFile([]);
    } finally {
      setLoading(false);
      if (fetchInteractions) {
        fetchInteractions(patientId);
      }
      setFile([]);
      localStorage.removeItem("interactionIdFirst");
    }
  }

  const patients = JSON.parse(localStorage.getItem("patients"));

  const handleAudioFile = async () => {
    // console.log("INN");
    setLoading(true);
    const fileName = file[0].name; // Get the original file name
    const fileExtension = fileName.split(".").pop();
    // console.log(fileExtension);
    const formData = new FormData();
    formData.append(
      "file_name",
      `audio_files/${userDetails?.patientId}_${Date.now()}.${fileExtension}`
    );
    formData.append(
      "audio_file",
      file[0],
      `${userDetails?.patientId}.${fileExtension}`
    );
    // console.log(formData, "FORMDATA");

    try {
      const response = await fetch(
        `${configJson.backend_URL}/soapPrompt/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        // console.log(result, "Audio stored in bucket");
        const url = result.gcs_uri;
        await updatedata(url);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setFile([]);
      // alert(`An error occurred while submitting the form.${error}`);
    }
  };
  // console.log(file, "FILE");
  // console.log(Date.now(), "DATE");
  //Check Status
  const checkInteractionStatus = async (interaction_id) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/interactions/status/${interaction_id}`,
        requestOptions
      );
      if (!response.ok) {
        toast.error("refersh the page");
      }
      const data = await response.json();
      if (data?.status_ok === "audio") {
        setTimeout(() => startPolling({ interaction_id }), 10000);
      } else if (data?.status_ok === "raw-text") {
        const changeStatus = {
          patient_id: data?.interaction?.patient_id,
          interaction_status: data?.interaction?.interaction_status,
        };
        if (decode?.role === "doctor") {
          dispatch(updatePatientStatus(changeStatus));
        }
        setTimeout(() => startPolling({ interaction_id }), 10000);
      } else if (data?.status_ok === "ingest") {
        const changeStatus = {
          patient_id: data?.interaction?.patient_id,
          interaction_status: data?.interaction?.interaction_status,
        };

        if (decode?.role === "doctor") {
          const targetPath = `/activity?id=${data?.interaction?.patient_id}`;
          console.log(targetPath, "targetPath");
          const t =
            window.location.pathname + window.location.search === targetPath;
          // console.log(window.location.pathname+window.location.search===`/activity?id=${data?.interaction?.patient_id}` && isRecording ,window.location.pathname+window.location.search===`/activity?id=${data?.interaction?.patient_id}` ,isRecording)
          if (t) {
            dispatch(updatePatientStatus(changeStatus));
            navigate(`/activity?id=${data?.interaction?.patient_id}`);
          } else {
            const changeStatus_color = {
              patient_id: data?.interaction?.patient_id,
              interaction_status: "6",
            };
            dispatch(updatePatientStatus(changeStatus_color));
          }
        } else if (decode?.role === "front office staff") {
          const targetPath = `/activity?id=${data?.interaction?.patient_id}`;
          if (
            window.location.pathname + window.location.search ===
            targetPath
          ) {
            navigate(
              `/activity?id=${data?.interaction?.patient_id}&doctor=null`
            );
          }
          // navigate(`/activity?id=${data?.interaction?.patient_id}&doctor=null`);
        }
      }
    } catch (error) {
      console.error("Error checking button status:", error);
    }
  };
  //check 10s ones
  function startPolling({ interaction_id }) {
    checkInteractionStatus(interaction_id);
  }


  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {medicineData?.medicines?.length === 0 && (
          <Modal.Title>Upload Document</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {/* <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" 
          style={{ background: 'linear-gradient(to right, #1C1B1B, #5B5B5B)', border: 'none' }}>
            {interactionDetailType !== ""
              ? interactionDetailType
              : "Select Document type"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {interactionTypes?.map((data) => {
              return (
                <Dropdown.Item
                  onClick={() =>
                    handleOptionSelect(
                      data.interaction_type_name,
                      data.interaction_type_id
                    )
                  }
                >
                  {data.interaction_type_name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown> */}
        {!isMedicine ? (
          <div className="mt-4">
            {openCam ? (
              <div>
                {file == null && (
                  <>
                    <Webcam
                      audio={false}
                      ref={cameraRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        facingMode: isFrontCamera
                          ? "user"
                          : { exact: "environment" },
                      }}
                    />
                    <button onClick={handleSwitchCamera}>Switch Camera</button>
                  </>
                )}
                {file && (
                  <img src={URL.createObjectURL(file[0])} alt="Captured Image" />
                )}
                <div
                  className="text-center m-auto py-1 px-2 rounded-xl bg-gray-400 w-fit mt-2 cursor-pointer"
                  onClick={handleCapture}
                >
                  {file == null ? "capture" : "Retake"}
                </div>
              </div>
            ) : (
              <>
                <label className="block mt-4">
                  <span className="text-gray-700">Upload File:</span>
                  <input
                    type="file"
                    className="mt-1 block w-full"
                    onChange={(e) => setFile(Array.from(e.target.files))}
                    multiple={true}
                  />
                </label>
                {/* {file && displayImage()} */}
              </>
            )}
            <button
              className="text-blue-500"
              onClick={() => {
                setOpenCam(!openCam);
                setFile(null);
              }}
            >
              {openCam ? "Or Upload File" : "Or Take Photo"}
            </button>
            {/* <div className="mt-4">
            <label className="block mt-4">
              <span className="text-gray-700">Notes/Prescription:</span>
              <textarea
                style={{
                  fontFamily: " monospace",
                  border: "1px solid gray",
                  borderRadius: "4px",
                }}
                // name="doctorNotes"
                type="textarea"
                className="mt-1 block w-full"
                // id="Notes-text"
                value={doctorUploadNotes}
                onChange={(e) => setDoctorUploadNotes(e.target.value)}
              />
            </label>
          </div> */}
          </div>
        ) : (
          <>
            <div className="w-fit">
              <table border="1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Dosage</th>
                    <th>Frequency</th>
                    <th>Duration</th>
                    <th>Intake Instructions</th>
                  </tr>
                </thead>
                <tbody>
                  {medicineData[gsLink].medicines.map((medicine, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          type="text"
                          value={medicine.name}
                          onChange={(e) =>
                            handleInputChange(e, index, "medicines", "name")
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={medicine.dosage || ""}
                          onChange={(e) =>
                            handleInputChange(e, index, "medicines", "dosage")
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={medicine.frequency || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              index,
                              "medicines",
                              "frequency"
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={medicine.duration || ""}
                          onChange={(e) =>
                            handleInputChange(e, index, "medicines", "duration")
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={medicine.intake_instructions || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              index,
                              "medicines",
                              "intake_instructions"
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {medicineData[gsLink].other_medicines?.length > 0 && (
                <>
                  <h4 className="my-2">Other Medicines</h4>
                  <table border="1">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        <th>Intake Instructions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicineData[gsLink].other_medicines?.map(
                        (medicine, index) => (
                          <tr key={index}>
                            <td>
                              <Input
                                type="text"
                                value={medicine.name}
                                onChange={(e) =>
                                  handleInputChange(e, index, "name")
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={medicine.dosage || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    "other_medicines",
                                    "dosage"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={medicine.frequency || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    "other_medicines",
                                    "frequency"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={medicine.duration || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    "other_medicines",
                                    "duration"
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={medicine.intake_instructions || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    "other_medicines",
                                    "intake_instructions"
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="text-white px-4 py-2 rounded"
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
          onClick={() => {
            handleCancel();
            setIsMedicine(false);
            setMedicineData({ medicines: [], otherMedicines: [] });
          }}
        >
          Cancel
        </button>
        <button
          className="text-white px-4 py-2 rounded"
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
          disabled={loading}
          onClick={() => {
            if (isMedicine) {
              handleMedicines();
            } else {
              if (
                (file && file?.length > 0 && file[0]?.type.includes("audio")) ||
                file[0]?.type.includes("webm")
              ) {
                handleAudioFile();
              } else {
                handleSubmitDocs();
              }
            }
          }}
        >
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : isMedicine ? (
            "Save"
          ) : (
            "Upload"
          )}
        </button>
      </Modal.Footer>
      {/* {showUploadStatusPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div
            className="p-6 rounded shadow-md border-1"
            style={{
              backgroundColor: "#fff",
              borderColor: "#5b5b5b",
            }}
          >
            <p>{uploadStatusMessage}</p>
            <div className="flex justify-center">
              <button
                className="text-white px-4 py-2 center"
                style={{
                  backgroundColor: "#1c1b1b",
                }}
                onClick={handleUploadStatusPopup}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )} */}
    </Modal>
  );
};
export default UploadFiles;
