import React, { useState } from "react";
import { Modal, Input } from "antd";
import axios from "axios";
import { configJson } from "../../Constent/config";
import Spinner from "react-bootstrap/Spinner";

const PrescModal = ({
  isPrescModal,
  setIsPrescModal,
  medicineData,
  setMedicineData,
  patientId,
  vitalsInteractionId,
}) => {
  const [loading, setLoading] = useState(false);

  const handleMedicineEdit = (e, index, type, field) => {
    const updatedData = { ...medicineData };
    const key = Object.keys(updatedData)[0]; // Dynamically get the first key
    updatedData[key][type][index][field] = e.target.value;
    setMedicineData(updatedData);
  };

  const firstKey = medicineData ? Object.keys(medicineData)[0] : null;
  const medicines = firstKey ? medicineData[firstKey]?.medicines || [] : [];
  const otherMedicines = firstKey ? medicineData[firstKey]?.other_medicines || [] : [];

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/updateMedicine/${vitalsInteractionId}`,
        {
          patient_id: patientId,
          medicineData,
        }
      );
      setIsPrescModal(false)
    } catch (err) {
      console.log(err);
      setIsPrescModal(false);
    }
  };

  return (
    <Modal
      open={isPrescModal}
      footer={null}
      onCancel={() => setIsPrescModal(false)}
      // width={620}
    >
      <div>
        <div className="font-semibold">Prescription</div>
        <table
          border="1"
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
          className="bg-transparent"
        >
          <thead>
            <tr>
              <th>Medicine Name</th>
              <th>Dosage</th>
              <th>Duration</th>
              <th>Frequency</th>
              <th>Instructions</th>
            </tr>
          </thead>
          <tbody>
            {medicines.map((medicine, idx) => (
              <tr key={idx}>
                <td>
                  <Input
                    type="text"
                    value={medicine.name}
                    onChange={(e) =>
                      handleMedicineEdit(e, idx, "medicines", "name")
                    }
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={medicine.dosage || "N/A"}
                    onChange={(e) =>
                      handleMedicineEdit(e, idx, "medicines", "dosage")
                    }
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={medicine?.duration}
                    onChange={(e) =>
                      handleMedicineEdit(e, idx, "medicines", "duration")
                    }
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={medicine?.frequency}
                    onChange={(e) =>
                      handleMedicineEdit(e, idx, "medicines", "frequency")
                    }
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={medicine.intake_instructions || "N/A"}
                    onChange={(e) =>
                      handleMedicineEdit(
                        e,
                        idx,
                        "medicines",
                        "intake_instructions"
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {otherMedicines.length > 0 && (
          <>
            <div className="font-semibold">Other Medicines</div>
            <table
              border="1"
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
              className="bg-transparent"
            >
              <thead>
                <tr>
                  <th>Medicine Name</th>
                  <th>Dosage</th>
                  <th>Duration</th>
                  <th>Frequency</th>
                  <th>Instructions</th>
                </tr>
              </thead>
              <tbody>
                {otherMedicines.map((medicine, idx) => (
                  <tr key={idx}>
                    <td>
                      <Input
                        type="text"
                        value={medicine.name}
                        onChange={(e) =>
                          handleMedicineEdit(e, idx, "other_medicines", "name")
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={medicine.dosage || "N/A"}
                        onChange={(e) =>
                          handleMedicineEdit(
                            e,
                            idx,
                            "other_medicines",
                            "dosage"
                          )
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={medicine.duration}
                        onChange={(e) =>
                          handleMedicineEdit(
                            e,
                            idx,
                            "other_medicines",
                            "duration"
                          )
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={medicine.frequency}
                        onChange={(e) =>
                          handleMedicineEdit(
                            e,
                            idx,
                            "other_medicines",
                            "frequency"
                          )
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        value={medicine.intake_instructions || "N/A"}
                        onChange={(e) =>
                          handleMedicineEdit(
                            e,
                            idx,
                            "other_medicines",
                            "intake_instructions"
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>

      <div className="flex items-center justify-end w-full">
        <button
          className="text-white px-4 py-2 rounded my-2"
          style={{
            background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
          }}
          onClick={handleUpdate}
          disabled={loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Update"}
        </button>
      </div>
    </Modal>
  );
};

export default PrescModal;
