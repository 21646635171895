import React, { useState, useEffect } from "react";
import { onlyDate } from "../../helpers";
import { Input } from "antd";
import axios from "axios";
import Streamer from "./Streamer";

const DentalChart = () => {
  const [teeth, setTeeth] = useState([
    {
      toothId: 18,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 17,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 16,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 15,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 14,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 13,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 12,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 11,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 21,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 22,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 23,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 24,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 25,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 26,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 27,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 28,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 48,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 47,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 46,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 45,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 44,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 43,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 42,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 41,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 31,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 32,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 33,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 34,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 35,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 36,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 37,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 38,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
  ]);
  const teeth1 = teeth.slice(0, 8);
  const teeth2 = teeth.slice(8, 16);
  const teeth3 = teeth.slice(16, 24);
  const teeth4 = teeth.slice(24, 32);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://api.example.com/updated-teeth");
      const updatedTeeth = await response.json();

      // Merge updated teeth data with the current state
      setTeeth((prevTeeth) => {
        // Map over the current state and update the relevant tooth
        return prevTeeth.map((tooth) => {
          const updatedTooth = updatedTeeth.find(
            (update) => update.toothId === tooth.toothId
          );
          // If an updated value exists for this tooth, merge it
          return updatedTooth ? { ...tooth, ...updatedTooth } : tooth;
        });
      });
    } catch (error) {
      console.error("Error fetching updated teeth data:", error);
    }
  };


  const handleInputChange = (index, field, value, position) => {
    // setTeeth((prevTeeth) => {
    //   const updatedTeeth = [...prevTeeth];
    //   if (Array.isArray(updatedTeeth[index][field])) {
    //     const updatedArray = [...updatedTeeth[index][field]];
    //     updatedArray[position] = value;
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: updatedArray };
    //     return updatedTeeth;
    //   } else {
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: value };
    //     return updatedTeeth;
    //   }
    // });
  };
  const handleInputChange1 = (index, field, value, position) => {
    // setTeeth1((prevTeeth) => {
    //   const updatedTeeth = [...prevTeeth];
    //   if (Array.isArray(updatedTeeth[index][field])) {
    //     const updatedArray = [...updatedTeeth[index][field]];
    //     updatedArray[position] = value;
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: updatedArray };
    //     return updatedTeeth;
    //   } else {
    //     updatedTeeth[index] = { ...updatedTeeth[index], [field]: value };
    //     return updatedTeeth;
    //   }
    // });
  };

  const maxVal = 12;
  const [width, setWidth] = useState(window.innerWidth * 0.5);
  const height = 100;

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 800) {
  //       setWidth(window.innerWidth * 1);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // const calculatePoints = (teethData, startOffset = 0) => {
  //   return teethData.flatMap((tooth, index) => {
  //     const xSpacing = width / (teethData.length * tooth.gingival.length);
  //     return tooth.gingival.map((gin, ginIndex) => [
  //       xSpacing * (index * tooth.gingival.length + ginIndex) + startOffset,
  //       height - (gin / maxVal) * height,
  //     ]);
  //   });
  // };

  // const polylinePoints = calculatePoints(teeth);

  // const polylinePoints1 = calculatePoints(teeth1);
  // const polylinePointsStr = polylinePoints.map((p) => p.join(",")).join(" ");
  // const polylinePoints1Str = polylinePoints1.map((p) => p.join(",")).join(" ");

  return (
    <>
    <Streamer />
      <div className="p-4">
        <div className="flex flex-col sm:flex-row items-center justify-between mb-2">
          <h1 className="text-xl sm:text-3xl font-bold mb-4">
            PERIODONTAL CHART
          </h1>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Date</label>
            <div className="p-2 py-1 border rounded-md shadow-sm border-blue-500">
              {onlyDate(Date.now())}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">
              Patient Last Name
            </label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">First Name</label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Date Of Birth</label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-start mb-4">
          <div className="flex items-center gap-6">
            <div className="flex items-center space-x-2 mb-2 md:mb-0">
              <input
                type="checkbox"
                id="initialExam"
                className="border-gray-300 rounded focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
              <label htmlFor="initialExam" className="text-sm font-medium">
                Initial Exam
              </label>
            </div>

            <div className="flex items-center space-x-2 mb-2 md:mb-0">
              <input
                type="checkbox"
                id="re-evaluation"
                className="border-gray-300 rounded focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
              <label htmlFor="reevaluation" className="text-sm font-medium">
                Reevaluation
              </label>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Clinician</label>
            <input
              type="text"
              className="p-2 py-1 w-full md:w-auto border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="overflow-x-auto pointer-events-none">
        <div className="w-[720px] md:w-full">
          <div className="text-xs">
            <div className="h-[250px] md:w-full flex items-center gap-6">
              {/* Table-1 */}
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="border-none">{"  "}</th>
                    {teeth1.map((tooth, index) => (
                      <th key={index}>{tooth.toothId}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="!border-none text-right">Mobility</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <Input
                          type="number"
                          value={tooth.buccal.mobility || 0}
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          // min={0}
                          // max={20}
                          // onChange={(e) =>
                          //   handleInputChange(
                          //     index,
                          //     "mobility",
                          //     parseInt(e.target.value, 10)
                          //   )
                          // }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Implant</td>
                    {teeth1.map((tooth, index) => (
                      <td
                        key={index}
                        className="p-1"
                        // onClick={() =>
                        //   handleInputChange(index, "implant", !tooth.implant)
                        // }
                      >
                        <div className="bg-gray-200">
                          <div
                            className={`m-auto size-3 ${
                              tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Furcation</td>
                    {teeth1.map((tooth, index) => {
                      if (index > 2 || tooth.buccal.isImplant)
                        return <td key={index}>-</td>;
                      return (
                        <td key={index}>
                          <div className="bg-gray-200">
                            {tooth?.buccal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth?.buccal?.furcation[0] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td className="!border-none text-right">Bleeding</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Plaque</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange(index, "plaque", !pl, plIndex)
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">GingivalMargin</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">ProbingDepth</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>

              {/* Table-2 */}
              <table className="w-[85%] h-[240px]">
                <thead>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <th key={index}>{tooth.toothId}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <Input
                          type="number"
                          value={tooth.buccal.mobility || 0}
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          // min={0}
                          // max={20}
                          // onChange={(e) =>
                          //   handleInputChange(
                          //     index,
                          //     "mobility",
                          //     parseInt(e.target.value, 10)
                          //   )
                          // }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td
                        key={index}
                        className="p-1"
                        // onClick={() =>
                        //   handleInputChange(index, "implant", !tooth.implant)
                        // }
                      >
                        <div className="bg-gray-200">
                          <div
                            className={`m-auto size-3 ${
                              tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => {
                      if (index < 5 || tooth.buccal.isImplant)
                        return <td key={index}>-</td>;
                      return (
                        <td key={index}>
                          <div className="bg-gray-200">
                            {tooth?.buccal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth?.buccal?.furcation[0] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange(index, "plaque", !pl, plIndex)
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex items-center mt-2 relative">
              <div className="text-xl font-semibold mr-4">Buccal</div>
              <div className="flex items-center justify-around relative gap-6 mt-4 !w-full">
                {/* Image-1 */}
                <div className="flex items-center ml-3">
                  {teeth1.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                                18 - index
                              }.png`
                            : `/dental/table1/periodontograma-dientes-arriba-${
                                18 - index
                              }.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.buccal?.furcation != null && (
                        <div className="absolute top-12 left-5 w-4 z-40">
                          {tooth?.buccal?.furcation[0] === 1 && (
                            <img src={`/dental/nulll.png`} />
                          )}
                          {tooth?.buccal?.furcation[0] === 2 && (
                            <img src={`/dental/half.png`} />
                          )}
                          {tooth?.buccal?.furcation[0] === 3 && (
                            <img src={`/dental/full.png`} />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Image-2 */}
                <div className="flex items-center mt-2">
                  {teeth2.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.implant
                            ? `/dental/table2/implant/periodontograma-dientes-arriba-tornillo-${
                                21 + index
                              }.png`
                            : `/dental/table2/periodontograma-dientes-arriba-${
                                21 + index
                              }.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.furcation != null && (
                        <img
                          src={`/dental/${
                            tooth.furcation === 1
                              ? "nulll"
                              : tooth.furcation === 2
                              ? "half"
                              : "full"
                          }.png`}
                          className="absolute top-12 left-5 w-4 z-40"
                        />
                      )}
                    </div>
                  ))}
                </div>

                <svg
                  width={width} // Use dynamic width for both SVGs
                  height={height}
                  className="absolute left-8 bottom-10"
                >
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}

                  <polyline
                    // points={polylinePointsStr}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                </svg>

                <svg
                  width={width}
                  height={height}
                  className="absolute right-5 bottom-10"
                >
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}

                  <polyline
                    // points={polylinePoints1Str}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="text-xs">
            <div className="flex items-center mt-2 relative">
              <div className="text-xl font-semibold mr-4">Palatal</div>
              <div className="flex items-center justify-around relative gap-6 !w-full">
                {/* Image - 3 */}
                <div className="flex items-center ml-6">
                  {teeth1.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table3/implant/periodontograma-dientes-arriba-tornillo-${
                                18 - index
                              }b.png`
                            : `/dental/table3/periodontograma-dientes-arriba-${
                                18 - index
                              }b.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.palatal?.furcation != null && (
                        <>
                          <div className="absolute top-12 left-1 w-4 z-40">
                            {tooth?.palatal?.furcation[0] == 1 && (
                              <img src={`/dental/nulll.png`} />
                            )}
                            {tooth?.palatal?.furcation[0] == 2 && (
                              <img src={`/dental/half.png`} />
                            )}
                            {tooth?.palatal?.furcation[0] == 3 && (
                              <img src={`/dental/full.png`} />
                            )}
                          </div>
                          <div className="absolute top-11 right-2 w-4 z-40">
                            {tooth?.palatal?.furcation[1] == 1 && (
                              <img src={`/dental/nulll.png`} />
                            )}
                            {tooth?.palatal?.furcation[1] == 2 && (
                              <img src={`/dental/half.png`} />
                            )}
                            {tooth?.palatal?.furcation[1] == 3 && (
                              <img src={`/dental/full.png`} />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>

                {/* Image -4 */}
                <div className="flex items-center mr-6">
                  {teeth2.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table4/implant/periodontograma-dientes-arriba-tornillo-${
                                21 + index
                              }b.png`
                            : `/dental/table4/periodontograma-dientes-arriba-${
                                21 + index
                              }b.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.palatal?.furcation != null && (
                        <>
                          <div className="absolute top-12 left-1 w-4 z-40">
                            {tooth?.palatal?.furcation[0] == 1 && (
                              <img src={`/dental/nulll.png`} />
                            )}
                            {tooth?.palatal?.furcation[0] == 2 && (
                              <img src={`/dental/half.png`} />
                            )}
                            {tooth?.palatal?.furcation[0] == 3 && (
                              <img src={`/dental/full.png`} />
                            )}
                          </div>
                          <div className="absolute top-11 right-2 w-4 z-40">
                            {tooth?.palatal?.furcation[1] == 1 && (
                              <img src={`/dental/nulll.png`} />
                            )}
                            {tooth?.palatal?.furcation[1] == 2 && (
                              <img src={`/dental/half.png`} />
                            )}
                            {tooth?.palatal?.furcation[1] == 3 && (
                              <img src={`/dental/full.png`} />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>

                <svg
                  width={width} // Use dynamic width for both SVGs
                  height={height}
                  className="absolute left-8 top-8"
                >
                  <polyline
                    // points={polylinePointsStr}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}
                </svg>

                <svg
                  width={width}
                  height={height}
                  className="absolute right-8 top-8"
                >
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}

                  <polyline
                    // points={polylinePoints1Str}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="h-[250px] md:w-full flex items-center gap-6 mt-3">
              {/* Table - 3 */}
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="!border-none text-right">GingivalMargin</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.palatal.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">ProbingDepth</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.palatal.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Plaque</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.palatal.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange(index, "plaque", !pl, plIndex)
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td className="!border-none text-right">Bleeding</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.palatal.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Furcation</td>
                    {teeth1.map((tooth, index) => {
                      if (index == 3 || index > 4 || tooth.buccal.isImplant)
                        return <td key={index}></td>;
                      return (
                        <td key={index}>
                          <div className="flex items-center gap-1">
                            <div className="bg-gray-200 w-1/2">
                              {tooth?.palatal.furcation != null ? (
                                <div className="w-[14px] m-auto z-40">
                                  {tooth?.palatal?.furcation[0] === 0 && (
                                    <div>-</div>
                                  )}
                                  {tooth?.palatal?.furcation[0] === 1 && (
                                    <img src={`/dental/nulll.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[0] === 2 && (
                                    <img src={`/dental/half.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[0] === 3 && (
                                    <img src={`/dental/full.png`} />
                                  )}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                            <div className="bg-gray-200 w-1/2">
                              {tooth?.palatal.furcation != null ? (
                                <div className="w-[14px] m-auto z-40">
                                  {tooth?.palatal?.furcation[1] === 0 && (
                                    <div>-</div>
                                  )}
                                  {tooth?.palatal?.furcation[1] === 1 && (
                                    <img src={`/dental/nulll.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[1] === 2 && (
                                    <img src={`/dental/half.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[1] === 3 && (
                                    <img src={`/dental/full.png`} />
                                  )}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Note</td>
                    {teeth1.map((tooth, index) => (
                      <td key={index}>
                        <div>{tooth.palatal.notes || "-"}</div>
                        {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value=
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
              {/* Table- 4 */}
              <table className="w-[85%] h-[175px]">
                <tbody>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.palatal.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange1(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.palatal.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange1(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-1 justify-center">
                          {tooth.palatal.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange1(
                              //     index,
                              //     "plaque",
                              //     !pl,
                              //     plIndex
                              //   )
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-1 justify-center">
                          {tooth.palatal.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange1(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => {
                      if (index < 3 || index == 4 || tooth.buccal.isImplant)
                        return <td key={index}></td>;
                      return (
                        <td key={index}>
                          <div className="flex items-center gap-1">
                            <div className="bg-gray-200 w-1/2">
                              {tooth?.palatal.furcation != null ? (
                                <div className="w-[14px] m-auto z-40">
                                  {tooth?.palatal?.furcation[0] === 0 && (
                                    <div>-</div>
                                  )}
                                  {tooth?.palatal?.furcation[0] === 1 && (
                                    <img src={`/dental/nulll.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[0] === 2 && (
                                    <img src={`/dental/half.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[0] === 3 && (
                                    <img src={`/dental/full.png`} />
                                  )}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                            <div className="bg-gray-200 w-1/2">
                              {tooth?.palatal.furcation != null ? (
                                <div className="w-[14px] m-auto z-40">
                                  {tooth?.palatal?.furcation[1] === 0 && (
                                    <div>-</div>
                                  )}
                                  {tooth?.palatal?.furcation[1] === 1 && (
                                    <img src={`/dental/nulll.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[1] === 2 && (
                                    <img src={`/dental/half.png`} />
                                  )}
                                  {tooth?.palatal?.furcation[1] === 3 && (
                                    <img src={`/dental/full.png`} />
                                  )}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    {teeth2.map((tooth, index) => (
                      <td key={index}>
                        <div>{tooth.palatal.notes || "-"}</div>
                        {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value={tooth.palatal.notes || '-'}
                          onChange={(e) =>
                            handleInputChange1(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="w-[720px] md:w-full">
          <div className="text-xs">
            <div className="h-[250px] md:w-full flex items-center gap-6">
              {/* Table-5 */}
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="!border-none text-right">Note</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div>{tooth.lingual.notes || "-"}</div>
                        {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value=
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Furcation</td>
                    {teeth3.map((tooth, index) => {
                      if (index == 3 || index > 4 || tooth.buccal.isImplant)
                        return <td key={index}>-</td>;
                      return (
                        <td key={index}>
                        <div className="bg-gray-200">
                          {tooth?.lingual.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth?.lingual?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth?.lingual?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth?.lingual?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Bleeding</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.lingual.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Plaque</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.lingual.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange(index, "plaque", !pl, plIndex)
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">GingivalMargin</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.lingual.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">ProbingDepth</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.lingual.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>

              {/* Table-6 */}
              <table className="w-[85%] h-[175px]">
                <tbody>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div>{tooth.lingual.notes || "-"}</div>
                        {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value={tooth.lingual.notes || '-'}
                          onChange={(e) =>
                            handleInputChange1(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => {
                      if (index < 3 || index == 4 || tooth.buccal.isImplant)
                        return <td key={index}>-</td>;
                      return (
                        <td key={index}>
                        <div className="bg-gray-200">
                          {tooth?.lingual.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth?.lingual?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth?.lingual?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth?.lingual?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </td>
                      );
                    })}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-1 justify-center">
                          {tooth.lingual.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange1(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-1 justify-center">
                          {tooth.lingual.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange1(
                              //     index,
                              //     "plaque",
                              //     !pl,
                              //     plIndex
                              //   )
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.lingual.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange1(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.lingual.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange1(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex items-center mt-2 relative">
              <div className="text-xl font-semibold mr-4">Lingual</div>
              <div className="flex items-center justify-around relative gap-6 mt-4 !w-full">
                {/* Image-5 */}
                <div className="flex items-center ml-3">
                  {teeth3.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table5/implant/periodontograma-dientes-abajo-tornillo-${
                                48 - index
                              }.png`
                            : `/dental/table5/periodontograma-dientes-abajo-${
                                48 - index
                              }.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.lingual?.furcation != null && (
                        <div className="absolute top-12 left-5 w-4 z-40">
                          {tooth?.lingual?.furcation[0] === 1 && (
                            <img src={`/dental/nulll.png`} />
                          )}
                          {tooth?.lingual?.furcation[0] === 2 && (
                            <img src={`/dental/half.png`} />
                          )}
                          {tooth?.lingual?.furcation[0] === 3 && (
                            <img src={`/dental/full.png`} />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Image-6 */}
                <div className="flex items-center mt-2">
                  {teeth4.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table6/implant/periodontograma-dientes-abajo-tornillo-${
                                31 + index
                              }.png`
                            : `/dental/table6/periodontograma-dientes-abajo-${
                                31 + index
                              }.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.lingual?.furcation != null && (
                        <div className="absolute top-12 left-5 w-4 z-40">
                          {tooth?.lingual?.furcation[0] === 1 && (
                            <img src={`/dental/nulll.png`} />
                          )}
                          {tooth?.lingual?.furcation[0] === 2 && (
                            <img src={`/dental/half.png`} />
                          )}
                          {tooth?.lingual?.furcation[0] === 3 && (
                            <img src={`/dental/full.png`} />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <svg
                  width={width} // Use dynamic width for both SVGs
                  height={height}
                  className="absolute left-8 bottom-10"
                >
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}

                  <polyline
                    // points={polylinePointsStr}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                </svg>

                <svg
                  width={width}
                  height={height}
                  className="absolute right-5 bottom-10"
                >
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}

                  <polyline
                    // points={polylinePoints1Str}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="text-xs">
            <div className="flex items-center mt-2 relative">
              <div className="text-xl font-semibold mr-4">Buccal</div>
              <div className="flex items-center justify-around relative gap-6 !w-full">
                {/* Image - 7 */}
                <div className="flex items-center ml-6">
                  {teeth3.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table7/implant/periodontograma-dientes-abajo-tornillo-${
                                48 - index
                              }b.png`
                            : `/dental/table7/periodontograma-dientes-abajo-${
                                48 - index
                              }b.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.buccal?.furcation != null && (
                        <>
                          <div className="absolute top-12 left-6 w-4 z-40">
                            {tooth?.buccal?.furcation[0] == 1 && (
                              <img src={`/dental/nulll.png`} />
                            )}
                            {tooth?.buccal?.furcation[0] == 2 && (
                              <img src={`/dental/half.png`} />
                            )}
                            {tooth?.buccal?.furcation[0] == 3 && (
                              <img src={`/dental/full.png`} />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>

                {/* Image -8 */}
                <div className="flex items-center mr-6">
                  {teeth4.map((tooth, index) => (
                    <div className="relative !w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table8/implant/periodontograma-dientes-abajo-tornillo-${
                                31 + index
                              }b.png`
                            : `/dental/table8/periodontograma-dientes-abajo-${
                                31 + index
                              }b.png`
                        }
                        className="!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth?.buccal?.furcation != null && (
                        <>
                          <div className="absolute top-12 left-6 w-4 z-40">
                            {tooth?.buccal?.furcation[0] == 1 && (
                              <img src={`/dental/nulll.png`} />
                            )}
                            {tooth?.buccal?.furcation[0] == 2 && (
                              <img src={`/dental/half.png`} />
                            )}
                            {tooth?.buccal?.furcation[0] == 3 && (
                              <img src={`/dental/full.png`} />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>

                <svg
                  width={width} // Use dynamic width for both SVGs
                  height={height}
                  className="absolute left-8 top-8"
                >
                  <polyline
                    // points={polylinePointsStr}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}
                </svg>

                <svg
                  width={width}
                  height={height}
                  className="absolute right-8 top-8"
                >
                  {Array.from({ length: maxVal }).map((_, i) => (
                    <line
                      key={i}
                      x1={0}
                      y1={(i / maxVal) * height}
                      x2={width}
                      y2={(i / maxVal) * height}
                      stroke="#ddd"
                      strokeWidth="2"
                    />
                  ))}

                  <polyline
                    // points={polylinePoints1Str}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="h-[250px] md:w-full flex items-center gap-6 mt-3">
              {/* Table - 7 */}
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="!border-none text-right">GingivalMargin</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">ProbingDepth</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Plaque</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange(index, "plaque", !pl, plIndex)
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Bleeding</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td className="!border-none text-right">Furcation</td>
                    {teeth3.map((tooth, index) => {
                      if (index > 2 || tooth.buccal.isImplant)
                        return <td key={index}>-</td>;
                      return (
                        <td key={index}>
                          <div className="bg-gray-200">
                            {tooth?.buccal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth?.buccal?.furcation[0] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="!border-none text-right">Implant</td>
                    {teeth3.map((tooth, index) => (
                      <td
                        key={index}
                        className="p-1"
                        // onClick={() =>
                        //   handleInputChange(index, "implant", !tooth.implant)
                        // }
                      >
                        <div className="bg-gray-200">
                          <div
                            className={`m-auto size-3 ${
                              tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td className="!border-none text-right">Mobility</td>
                    {teeth3.map((tooth, index) => (
                      <td key={index}>
                        <Input
                          type="number"
                          value={tooth.buccal.mobility || 0}
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          // min={0}
                          // max={20}
                          // onChange={(e) =>
                          //   handleInputChange(
                          //     index,
                          //     "mobility",
                          //     parseInt(e.target.value, 10)
                          //   )
                          // }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th className="border-none">{"  "}</th>
                    {teeth3.map((tooth, index) => (
                      <th key={index}>{tooth.toothId}</th>
                    ))}
                  </tr>
                </tbody>
              </table>
              {/* Table- 8 */}
              <table className="w-[85%] h-[225px]">
                <tbody>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                            <Input
                              type="number"
                              value={gin}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "gingival",
                              //     parseInt(e.target.value, 10),
                              //     ginIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center justify-center">
                          {tooth.buccal.probingDepth.map((prob, probIndex) => (
                            <Input
                              type="number"
                              value={prob}
                              // onChange={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "probing",
                              //     parseInt(e.target.value, 10),
                              //     probIndex
                              //   )
                              // }
                              className="p-0 w-1/3 bg-gray-200 text-center"
                            />
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.plaque.map((pl, plIndex) => (
                            <button
                              className={`${
                                pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                              // onClick={() =>
                              //   handleInputChange(index, "plaque", !pl, plIndex)
                              // }
                            ></button>
                          ))}
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <div className="flex items-center gap-[2px] justify-center">
                          {tooth.buccal.bleedOnProbe.map(
                            (bleed, bleedIndex) => (
                              <button
                                // onClick={(e) =>
                                //   handleInputChange(
                                //     index,
                                //     "bleeding",
                                //     !bleed,
                                //     bleedIndex
                                //   )
                                // }
                                className={`${
                                  bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                              ></button>
                            )
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    {teeth4.map((tooth, index) => {
                      if (index < 5 || tooth.buccal.isImplant)
                        return <td key={index}>-</td>;
                      return (
                        <td key={index}>
                          <div className="bg-gray-200">
                            {tooth?.buccal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth?.buccal?.furcation[0] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth?.buccal?.furcation[0] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td
                        key={index}
                        className="p-1"
                        // onClick={() =>
                        //   handleInputChange(index, "implant", !tooth.implant)
                        // }
                      >
                        <div className="bg-gray-200">
                          <div
                            className={`m-auto size-3 ${
                              tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <td key={index}>
                        <Input
                          type="number"
                          value={tooth.buccal.mobility || 0}
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          // min={0}
                          // max={20}
                          // onChange={(e) =>
                          //   handleInputChange(
                          //     index,
                          //     "mobility",
                          //     parseInt(e.target.value, 10)
                          //   )
                          // }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {teeth4.map((tooth, index) => (
                      <th key={index}>{tooth.toothId}</th>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default DentalChart;
