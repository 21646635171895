import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { configJson } from "../../Constent/config";
import { Button, Spinner } from "react-bootstrap";
import {
  MedicationContainer,
  MedicationFormCOntainer,
  TemplateButton,
} from "./MedicationTemplatesStyle";
import { toast } from "react-toastify";

export const MedicationForm = ({
  medication_id,
  formdata,
  setMedications,
  medicationdata,
}) => {
  // const { medication_id } = useParams();  // Get medication_id from URL (if it's a route parameter)
  // console.log(formdata, medication_id);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch medication data if editing
  useEffect(() => {
    if (!medication_id) {
      reset();
    } else {
      // Set the fetched medication data in the form
      setValue("medication_name", formdata.medication_name);
      setValue("dosage", formdata.dosage);
      setValue("medication_type",formdata?.medication_type)
      setValue("frequency_morning", formdata.frequency_morning);
      setValue("frequency_afternoon", formdata.frequency_afternoon);
      setValue("frequency_night", formdata.frequency_night);
      setValue("duration", formdata.duration);
      setValue("route",formdata?.route)
      setValue("instructions", formdata.instructions);
    }
  }, [medication_id, setValue]);

  const onSubmit = async (data) => {
    const datas = { ...data, template_id: formdata.template_id };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/medicationList/medication/${
          medication_id || ""
        }`,
        {
          method: medication_id ? "PUT" : "POST", // PUT for updating, POST for creating
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datas),
        }
      );

      const result = await response.json();
      if (response.ok) {
        toast(result.message);
        if (medication_id) {
          setMedications((prevTemplates) =>
            prevTemplates.map((template) =>
              template.medication_id === medication_id ? result.data : template
            )
          );
        } else {
          setMedications([...medicationdata, result.data]);
        } // Handle success (e.g., show a success message)
        reset();
      } else {
        toast(result.message); // Handle failure
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div>
      <MedicationFormCOntainer>
        <p className="heading-text">
          {medication_id ? "Update Medication" : "Create Medication"}
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="medication-form-field"
        >
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="medication_name" className="form-label">
                Name:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${
                  errors.medication_name ? "is-invalid" : ""
                }`}
                id="medication_name"
                placeholder="e.g., Paracetemol"
                {...register("medication_name", {
                  required: "Medication name is required",
                })}
              />
              {errors.medication_name && (
                <div className="invalid-feedback">
                  {errors.medication_name.message}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="dosage" className="form-label">
                Dosage:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${errors.dosage ? "is-invalid" : ""}`}
                id="dosage"
                placeholder="e.g., 500mg"
                {...register("dosage", { required: "Dosage is required" })}
              />
              {errors.dosage && (
                <div className="invalid-feedback">{errors.dosage.message}</div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="medication_type" className="form-label">
              medication Type:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${errors.medication_type ? "is-invalid" : ""}`}
                id="medication_type"
                placeholder="e.g., Tablet"
                {...register("medication_type", { required: "medication_type is required" })}
              />
              {errors.medication_type && (
                <div className="invalid-feedback">{errors.medication_type.message}</div>
              )}
            </div>
          </div>

          <p className="p-0">Frequency</p>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="frequency_morning" className="form-label">
                Morning:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${
                  errors.frequency_morning ? "is-invalid" : ""
                }`}
                id="frequency_morning"
                placeholder="e.g., 1"
                {...register("frequency_morning", {
                  required: "Morning frequency is required",
                })}
              />
              {errors.frequency_morning && (
                <div className="invalid-feedback">
                  {errors.frequency_morning.message}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="frequency_afternoon" className="form-label">
                Afternoon:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${
                  errors.frequency_afternoon ? "is-invalid" : ""
                }`}
                id="frequency_afternoon"
                placeholder="e.g., 1"
                {...register("frequency_afternoon", {
                  required: "Afternoon frequency is required",
                })}
              />
              {errors.frequency_afternoon && (
                <div className="invalid-feedback">
                  {errors.frequency_afternoon.message}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="frequency_night" className="form-label">
                Night:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${
                  errors.frequency_night ? "is-invalid" : ""
                }`}
                id="frequency_night"
                placeholder="e.g., 1"
                {...register("frequency_night", {
                  required: "Night frequency is required",
                })}
              />
              {errors.frequency_night && (
                <div className="invalid-feedback">
                  {errors.frequency_night.message}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="duration" className="form-label">
                Route:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${errors.route ? "is-invalid" : ""}`}
                id="route"
                placeholder="e.g., Oral"
                {...register("route", {
                  required: "route is required",
                })}
              />
              {errors.duration && (
                <div className="invalid-feedback">{errors.route.message}</div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="duration" className="form-label">
                Period:
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                className={`form-control ${
                  errors.duration ? "is-invalid" : ""
                }`}
                id="duration"
                placeholder="e.g., 7 Days"
                {...register("duration", {
                  required: "Duration is required",
                })}
              />
              {errors.duration && (
                <div className="invalid-feedback">
                  {errors.duration.message}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="instructions" className="form-label">
                Remarkes:
              </label>
            </div>
            <div className="col-md-8">
              <textarea
                className="form-control"
                id="instructions"
                placeholder="e.g., take after meals"
                {...register("instructions")}
              />
            </div>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary theme-button">
              Submit
            </button>
          </div>
        </form>
      </MedicationFormCOntainer>
    </div>
  );
};

// Table Component to display Medications
export const MedicationTable = ({ tabledata, medicationtableshow }) => {
  const [medications, setMedications] = useState([]);
  const [error, setError] = useState(null);
  const [showform, setShowform] = useState(true);
  const [selecteddata, setSelecteddata] = useState({});
  const [loading, setLoading] = useState(false);

  // Fetch medications data from API
  const fetchMedications = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/medicationList/medication/temp/${tabledata}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch medications");
      }
      const data = await response.json();
      setMedications(data.data); // Assumes `data` is an array of medication objects
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handelDelete = async (medicationId) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/medicationList/medication/${medicationId}`,
        {
          method: "DELETE",
        }
      );

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message); // Show success message
        setMedications(
          medications.filter((med) => med.medication_id !== medicationId)
        ); // Update state to remove deleted medication
      } else {
        toast.error(result.message); // Show error message if delete failed
      }
    } catch (error) {
      toast.error("An error occurred while deleting the medication.");
      console.error("Error deleting medication:", error);
    }
  };

  useEffect(() => {
    fetchMedications();
    setSelecteddata({ template_id: tabledata });
  }, []);

  //edit model open
  const handletoedit = (data) => {
    setSelecteddata({ ...data, template_id: tabledata });
    setShowform(true);
  };
  //show form
  const showmedicatin = () => {
    setShowform(true);
  };
  //hide form
  const hidemedication = () => {
    setShowform(false);
    setSelecteddata({});
  };
  //set new medication data
  const setMedicationsdata = (data) => {
    setMedications(data);
  };
  return (
    <div className="">
      {error && <p className="text-danger">{error}</p>}
      <TemplateButton>
        {/* {!showform ? (
          <Button type="button" onClick={() => showmedicatin()}>
            Add Medication
          </Button>
        ) : (
          <Button type="button" onClick={() => hidemedication()}>
            hide
          </Button>
        )} */}
        <Button type="button" onClick={() => medicationtableshow(false)}>
          back
        </Button>
      </TemplateButton>
      <MedicationContainer>
        <div className="medicatiob-form">
          {showform ? (
            <MedicationForm
              medication_id={selecteddata?.medication_id}
              formdata={selecteddata}
              setMedications={setMedicationsdata}
              medicationdata={medications}
            />
          ) : (
            ""
          )}
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="medicatiob-table">
            <h2>Medications List</h2>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <th>Medication ID</th>
            <th>Template ID</th> */}
                    <th>Medication Name</th>
                    <th>Dosage</th>
                    <th>medication Type</th>
                    <th>Frequency (Morning)</th>
                    <th>Frequency (Afternoon)</th>
                    <th>Frequency (Night)</th>
                    <th>Period</th>
                    <th>Route</th>
                    <th>Remarkes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {medications.length > 0 ? (
                    medications.map((med, index) => (
                      <tr key={index}>
                        {/* <td>{med.medication_id}</td>
                <td>{med.template_id}</td> */}
                        <td>{med.medication_name}</td>
                        <td>{med.dosage}</td>
                        <td>{med?.medication_type}</td>
                        <td>{med.frequency_morning}</td>
                        <td>{med.frequency_afternoon}</td>
                        <td>{med.frequency_night}</td>
                        <td>{med.duration}</td>
                        <td>{med?.route}</td>
                        <td>{med.instructions}</td>
                        <td className="flex items-center justify-between gap-2">
                          <Button
                            type="buttton"
                            onClick={() => handletoedit(med)}
                          >
                            Edit
                          </Button>
                          <Button
                            type="buttton"
                            onClick={() => handelDelete(med.medication_id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No medications found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </MedicationContainer>
    </div>
  );
};
