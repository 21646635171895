import React, { useState, useRef } from "react";
import { Mic as MicIcon, Stop as StopIcon } from "@mui/icons-material";
import lamejs from "lamejs";

const Streamer = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcriptions, setTranscriptions] = useState([]);
  const mediaRecorderRef = useRef(null);
  const [blobs, setBlobs] = useState([])


  const startRecording = async () => {
    setBlobs([])
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          const audioBlob = event.data;
          console.log(audioBlob)
          setBlobs((prev) => [...prev, audioBlob])
          const mp3Blob = await convertToMP3(audioBlob);
          console.log(mp3Blob)
          // setBlobs((prev) => [...prev, mp3Blob])
          await sendAudioForTranscription(mp3Blob);
        }
      };

      mediaRecorderRef.current.start(1000); // Capture audio in 1-second chunks
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };


  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    mediaRecorderRef.current = null;
  };

  const convertToMP3 = async (audioBlob) => {
    try {
        // Fetch the array buffer
        const arrayBuffer = await audioBlob.arrayBuffer();

        // Ensure the buffer length is even
        if (arrayBuffer.byteLength % 2 !== 0) {
            console.warn("Array buffer length is not even. Trimming to fix.");
            const trimmedBuffer = arrayBuffer.slice(0, arrayBuffer.byteLength - 1);
            return processAudioBuffer(trimmedBuffer);
        }

        return processAudioBuffer(arrayBuffer);
    } catch (error) {
        console.error("Error converting to MP3:", error);
        return null;
    }
};

const processAudioBuffer = (arrayBuffer) => {
    const wav = new Int16Array(arrayBuffer); // Create Int16Array from buffer
    const mp3Encoder = new lamejs.Mp3Encoder(1, 44100, 128); // Mono, 44.1kHz, 128kbps
    const mp3Data = [];
    let samples = wav;

    // Encode MP3
    for (let i = 0; i < samples.length; i += 1152) {
        const sampleChunk = samples.subarray(i, i + 1152);
        const mp3Chunk = mp3Encoder.encodeBuffer(sampleChunk);
        if (mp3Chunk.length > 0) {
            mp3Data.push(mp3Chunk);
        }
    }

    const finalChunk = mp3Encoder.flush();
    if (finalChunk.length > 0) {
        mp3Data.push(finalChunk);
    }

    if (mp3Data.length === 0) {
        console.warn("MP3 data is empty after encoding.");
        return null;
    }

    return new Blob(mp3Data, { type: "audio/mp3" });
};

  
  const sendAudioForTranscription = async (mp3Blob) => {
    try {
      const formData = new FormData();
      formData.append("question_audio", mp3Blob, "audio.mp3");
    //   formData.append("interaction_id", "2644");

      const response = await fetch(
        "https://ffmpeg-app.medscribe.in/transcribe-audio/",
        {
          method: "POST",
          body: formData,
        }
      );
      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error sending audio:", error);
    }
  };

  const [audioUrl, setAudioUrl] = useState(null);

  const handleCombineAndPlay = () => {
    const combinedBlob = new Blob(blobs, { type: 'audio/webm;codecs=opus' });
    const url = URL.createObjectURL(combinedBlob);
    setAudioUrl(url);
  };
  

  return (
    <div>
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className={`relative inline-flex items-center justify-center p-3 overflow-hidden text-white font-medium transition duration-300 ease-out rounded-full ${
          isRecording
            ? "bg-red-600  hover:bg-red-700 animate-pulse "
            : "bg-green-600 hover:bg-green-700"
        }`}
      >
        {isRecording ? (
          <StopIcon sx={{ fontSize: 30 }} />
        ) : (
          <MicIcon sx={{ fontSize: 30 }} />
        )}
      </button>
      <div>
      <button onClick={handleCombineAndPlay}>Combine & Play</button>
      {audioUrl && <audio controls src={audioUrl} />}
    </div>
    </div>
  );
};

export default Streamer;
