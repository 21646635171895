import React, { useEffect, useState, useRef } from "react";
import { configJson } from "../../../../Constent/config";
import {
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Spinner,
  Image,
  InputGroup,
} from "react-bootstrap";
import {
  MicSection,
  ChatFooter,
  UserResponse,
  BotResponse,
  QueryResponse,
  ChatContainer,
} from "../../../ThemeStyle/ChatbotStyle";
import { v4 as uuidv4 } from "uuid";
import { FaMicrophone, FaStop, FaVideo } from "react-icons/fa6";
import axios from "axios";

import MarkdownDisplay from "./MarkdownDisplay";
import FormatMessage from "./FormatMessages";
import userImg from "../../../../assets/user/AvatarIcon.png";
import bot from "../../../../assets/user/bot-icon.png";
import ChatAudioText from "./ChatAudioText";
import "./chatbot.css";
import { FaCamera, FaTimes } from "react-icons/fa";
import RecordingScreen from "../../../Interaction-Detail-Page/RecordingScreen";
import { RecordContainer } from "../../../Interaction-Detail-Page/ActivityStyle";

import OutPatientTemplateUS from "../Components/OutpatientDefaultTemplateUS";
import { jwtDecode } from "jwt-decode";
import Audiorecord from "./Audiorecord";
import { useLocation } from "react-router-dom";
import DischargeSummary from "../../../Templates/DischargeSummary";
import AudioStreamer from "./FeedaudioRecord";
import { FaPlus } from "react-icons/fa";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { Spin } from "antd";
import OutpatientDataSheetGunam from "./Gunnamoutpatientdatatemplate";

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile/i.test(userAgent)) {
    return "Mobile";
  }
  if (/tablet/i.test(userAgent)) {
    return "Tablet";
  }
  return "Desktop";
};

const getBrowserName = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes("chrome")) return "Chrome";
  if (userAgent.includes("firefox")) return "Firefox";
  if (userAgent.includes("safari") && !userAgent.includes("chrome"))
    return "Safari";
  if (userAgent.includes("edg")) return "Edge";
  if (userAgent.includes("msie") || userAgent.includes("trident"))
    return "Internet Explorer";
  return "Unknown Browser";
};
const ChatbotQuery = ({
  organizationId,
  organizationCountry,
  doctorIdDatasheet,
  doctorId,
  patientId,
  // staticQns,
  // setStaticQns,
  role,
  ISChecked,
  setChecked,
  fetchInteractions,
  showBillingTemplate,
  setShowBillingTemplate, // Receive from props
  setUploadModal,
  setVitalsModal,
  audioUrlInteractionId,
  setSummaryDrawer,
  setShowTempComp,
  interactionIdFirst,
  lastInteraction,
  IsVisitUpdated,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [botError, setBotError] = useState(false);
  const [botFetch, setBotFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receivedFiles, setReceivedFiles] = useState([]);
  const [voiceInput, setVoiceInput] = useState("");
  const sessionId = useRef(uuidv4());
  const [queryResponse, setQueryResponse] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [staticQns, setStaticQns] = useState("");
  const [showOutPatientTemplate, setShowOutPatientTemplate] = useState(false);
  const [isOutPatientResponse, setIsOutPatientResponse] = useState(false);
  const [decode, setDecode] = useState({});
  const [isDischargeSummary, setIsDischargeSummary] = useState(false);
  const [streamdata, setStreamdata] = useState(false);
  const canvasRef = useRef(null);
  const [isEraser, setIsEraser] = useState(false);

  const patientName = `${patientId.first_name}${patientId.last_name}`;
  const patientNamewithouspace = `${patientId.first_name?.trim() || ""}${
    patientId.last_name?.trim() || ""
  }`;
  // console.log(patientId, "Intractnlist");
  const doctorName = `${decode.first_name} ${decode.last_name}`;
  const salutation = decode.salutation;
  const country = decode.country;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const patId = queryParams.get("id");
  const device = getDeviceType();
  const browser = getBrowserName();
  const shouldRenderAudioRecord =
    device === "Mobile" ||
    device === "Tablet" ||
    browser === "Safari" ||
    browser === "Unknown Browser";
  // console.log("device", device, browser);
  // console.log("countrynamechat",country);
  // const [doctorSpecialty, setDoctorSpecialty] = useState("");
  // console.log(patientId, "Interaction Details");
  // console.log("chatfirstint",interactionIdFirst)

  // console.log(organizationId, "orgid");
  //   // console.log(patientId.Interactions[0]?.token_number, "token number");

  const interactions = patientId?.Interactions; // Array of interactions

  // useEffect(() => {
  //   const streaming = sessionStorage.getItem("isStreaming");
  //   setStreamdata(streaming)
  // }, [])

  // Find the first interaction that has an InteractionDetail with interaction_detail_type 'Audio URL'
  const audioInteractionId = interactions
    ?.find((interaction) =>
      interaction.InteractionDetails?.some(
        (detail) => detail.interaction_detail_type === "Audio URL"
      )
    )
    ?.InteractionDetails?.find(
      (detail) => detail.interaction_detail_type === "Audio URL"
    )?.interaction_id;

  console.log(audioInteractionId, "Interaction Details with Audio URL");
  const dateTime = new Date();
  const formattedDate = dateTime
    .toLocaleDateString("en-GB")
    .replace(/\//g, "-"); // Replace slashes with hyphens
  const formattedTime = dateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }); // Format as HH:MM without AM/PM

  const displayDateTime = `${formattedDate}, ${formattedTime}`;

  const dateTimeUS = new Date();

  // Format the date in MM/DD/YYYY format
  const formattedDateUS = dateTimeUS
    .toLocaleDateString("en-US")
    .replace(/\//g, "-"); // Replace slashes with hyphens if necessary

  // Format the time as HH:MM (24-hour format) without AM/PM
  const formattedTimeUS = dateTimeUS.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });

  const getLatestDrawingTemplateContent = (patientData) => {
    if (!patientData || !Array.isArray(patientData.Interactions)) {
      console.error("Invalid patient data or Interactions is not an array");
      return null;
    }

    // Flatten all InteractionDetails from all Interactions safely
    const allInteractionDetails = patientData.Interactions.reduce(
      (acc, interaction) => {
        if (
          interaction.InteractionDetails &&
          Array.isArray(interaction.InteractionDetails)
        ) {
          return acc.concat(interaction.InteractionDetails);
        }
        return acc; // Skip if InteractionDetails is undefined or not an array
      },
      []
    );

    // Filter the InteractionDetails for type "Drawing Template"
    const drawingTemplates = allInteractionDetails.filter(
      (detail) => detail.interaction_detail_type === "Drawing Template"
    );

    // Sort the filtered templates by updated_at in descending order
    drawingTemplates.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    );

    // Return the processedFileContent of the latest template or null if none are found
    return drawingTemplates[0]?.processedFileContent || null;
  };

  // Usage
  const latestDrawingTemplateContent =
    getLatestDrawingTemplateContent(patientId);
  // console.log(latestDrawingTemplateContent, "Latest Drawing Template Content");

  // Usage
  const latestDrawingTemplate = getLatestDrawingTemplateContent(patientId);
  // console.log(latestDrawingTemplate, "Latest Drawing Template");

  const handleExportImageUpload = async () => {
    if (canvasRef.current) {
      try {
        // Check if the canvas is empty using the `exportPaths` method
        const paths = await canvasRef.current.exportPaths();
        if (!paths || paths.length === 0) {
          return;
        }

        console.log("Canvas has content, proceeding to export...");

        // Export the canvas content as a PNG image
        const image = await canvasRef.current.exportImage("png");

        // Convert the base64 image to a Blob
        const response = await fetch(image);
        const blob = await response.blob();

        // Generate a unique file name
        const fileName = `${patientId.patient_id}_${Date.now()}.png`;

        // Create FormData for the API request
        const formData = new FormData();
        formData.append("patient_id", patientId.patient_id);
        formData.append("doctor_id", parseFloat(doctorId));
        formData.append(
          "interaction_date",
          new Date().toISOString().slice(0, 19).replace("T", " ")
        );
        formData.append("interaction_type", "");
        formData.append("interaction_status", "");
        formData.append("interaction_notes", "");
        formData.append("interaction_detail_type", "Drawing Template");
        formData.append("file", blob, fileName);
        formData.append("file_name", `files/${fileName}`);

        console.log("Uploading image...");
        const result = await axios.post(
          `${configJson.backend_URL}/interaction/createImgwithoutingest`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (result?.status) {
          console.log("Image uploaded successfully:", result.data);
        }
      } catch (error) {
        console.error("Error exporting or uploading the image:", error);
      }
    } else {
      console.error("Canvas reference is not available");
    }
  };

  // Combine the formatted date and time
  const displayDateTimeUS = `${formattedDateUS}`;

  const setVoiceInputText = (data) => {
    // setStaticQns(null);
    setInputValue(data);
  };
  const _init_message = [
    {
      author: "bot",
      content: "How can i help you",
    },
  ];
  const [messages, setMessage] = useState(_init_message);
  const [close, setClose] = useState(true);
  const [isBot, setIsBot] = useState(false);

  // Define the function to handle receiving files data from ChatImg
  const handleFilesData = (filesData) => {
    // setReceivedFiles(filesData);
    setReceivedFiles((prevFiles) => [...prevFiles, ...filesData]);
  };
  //   const handleCamFilesData = (camFilesData) => {
  //     console.log(233, camFilesData);
  //     setReceivedFiles((prevFiles) => [...prevFiles, ...camFilesData]);
  //   };
  const handleInputChange = (event) => {
    // setStaticQns(null);
    setInputValue(event.target.value);
  };

  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
    }
  };

  const toggleEraser = () => {
    if (canvasRef.current) {
      if (isEraser) {
        canvasRef.current.eraseMode(false); // Switch to draw mode
      } else {
        canvasRef.current.eraseMode(true); // Switch to eraser mode
      }
      setIsEraser(!isEraser);
    }
  };

  const handleKeyDown = (event) => {
    // console.log("keypress");
    setStaticQns("");
    if (!inputValue || botFetch) {
      return null;
    }
    if (event.key === "Enter") {
      sendQuestion();
    }
  };

  // Set token and fetch doctors
  // Add this useEffect to check if the doctor specialty is fetched correctly
  useEffect(() => {
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
      setDecode(decoded);
    }
  }, []);

  // Later in the component, you can also log doctorSpecialty to confirm it’s updated
  // useEffect(() => {
  //   console.log("Doctor Specialty State:", doctorSpecialty);
  // }, [doctorSpecialty]); // Only run this effect when doctorSpecialty changes

  const handleAnswerChangeUS = (
    updatedSummary,
    updatedDiagnosis,
    prescriptionData,
    updatedAssessment,
    editableVitals
  ) => {
    console.log("summarychat", updatedSummary);
    const formatPrescriptionData = (prescriptions) => {
      if (!Array.isArray(prescriptions)) {
        return {};
      }

      return prescriptions.map((prescription) => ({
        medication_template: {
          medication_name: prescription.medication_name,
          dosage: prescription.dosage,
          frequency_morning: parseFloat(prescription.frequency.morning) || 0,
          frequency_afternoon:
            parseFloat(prescription.frequency.afternoon) || 0,
          frequency_night: parseFloat(prescription.frequency.night) || 0,
          duration: prescription.duration,
          instructions: prescription.instructions,
        },
      }));
    };

    // Usage
    const formattedData = formatPrescriptionData(prescriptionData);

    // Converting to JSON string if needed
    const jsonData = JSON.stringify(formattedData);

    // console.log("Formatted Data:", formattedData);
    // console.log("JSON Data:", jsonData);

    // Check if the table was edited
    // if (isTableEdited) {
    //   console.log("Table true");
    //   console.log(templateData,"templatedataanswerchange")
    // }

    // Setting the query response
    setQueryResponse({
      answer: {
        updatedSummary,
        updatedDiagnosis,
        jsonData,
        updatedAssessment,
        editableVitals,
      },
    });
  };

  const handleAnswerChange = (
    updatedDiagnosis,
    prescriptionData,
    updatedAssessment,
    editableVitals
  ) => {
    const formatPrescriptionData = (prescriptions) => {
      if (!Array.isArray(prescriptions)) {
        return {};
      }

      return prescriptions.map((prescription) => ({
        medication_template: {
          medication_name: prescription.medication_name,
          dosage: prescription.dosage,
          frequency_morning: parseFloat(prescription.frequency.morning) || 0,
          frequency_afternoon:
            parseFloat(prescription.frequency.afternoon) || 0,
          frequency_night: parseFloat(prescription.frequency.night) || 0,
          duration: prescription.duration,
          instructions: prescription.instructions,
        },
      }));
    };

    // Usage
    const formattedData = formatPrescriptionData(prescriptionData);

    // Converting to JSON string if needed
    const jsonData = JSON.stringify(formattedData);

    // console.log("Formatted Data:", formattedData);
    // console.log("JSON Data:", jsonData);

    // Check if the table was edited
    // if (isTableEdited) {
    //   console.log("Table true");
    //   console.log(templateData,"templatedataanswerchange")
    // }

    // Setting the query response
    setQueryResponse({
      answer: {
        updatedDiagnosis,
        jsonData,
        updatedAssessment,
        editableVitals,
      },
    });
  };

  // console.log(queryResponse,"queryrespon");
  function uploadChatImg(receivedFiles) {
    // console.log(228, receivedFiles);
    return new Promise(async (resolve) => {
      try {
        let formdata = new FormData();
        if (!receivedFiles?.length) {
          throw "file cannot be empty";
        }
        receivedFiles.forEach((file) => formdata.append("file", file));

        // let requestOptions = {
        //   method: "POST",
        //   body: formdata,
        //   redirect: "follow",
        // };
        // let url = `${configJson.CHATBOT_URL}/api/simmer/v1/accounts/${merchant_user["company_id"]}/file/upload/chatinputimages`;

        // const response = await fetch(url, requestOptions);
        // if (!response.ok) {
        //   throw "error";
        // }
        // let data = await response.json();
        // resolve([null, data.public_link]);
      } catch (error) {
        console.log(error);
        resolve([error, null]);
      }
    });
  }
  const removeFile = (name) => {
    setReceivedFiles((files) => files.filter((file) => file.name !== name));
  };
  async function middlewaresubmission({
    question,
    product_name = "",
    blob_url = null,
  }) {
    if (botFetch) {
      return;
    }
    setBotFetch(true);

    await sendQuestion({
      question: question,
      product_name: product_name,
      blob_url,
      // chatImgLink,

      //   user_id = form_data.get("user_id")
      //   patient_id = form_data.get("patient_id", None)
      //   doctor_id = int(form_data.get('doctor_id',0))
      //   collection_name = form_data.get('collection_name')
      //   question_text = form_data.get("question_text")
      //   question_audio = files.get('question_audio')
    });
    setBotFetch(false);
  }

  function changelastmessage(newmessageObj, fallback = false) {
    setMessage((prevstate) => {
      let tempArr = [...prevstate];
      tempArr.pop();
      if (newmessageObj["question_audio_link"]) {
        tempArr.pop();
        tempArr.push({
          audio_link: newmessageObj["question_audio_link"],
          author: "user",
          content: "",
          audio_text: newmessageObj["question"],
        });
      }
      let final_output;
      if (fallback) {
        newmessageObj = {
          author: "bot",
          content: "",
          temp: true,
          chatLoader: true,
        };
      }
      final_output = [...tempArr, newmessageObj];
      // sync_message_session(final_output);
      return final_output;
    });
  }
  function sendQuestion(query) {
    setQueryResponse();
    setLoading(true);
    setIsBot(true);
    setShowOutPatientTemplate(false); // Reset the template state initially

    return new Promise(async (resolve) => {
      const session_id = sessionId.current;
      const formData = new FormData();
      formData.append("user_id", organizationId);
      formData.append("user_country", organizationCountry);
      formData.append("question_text", query ? query : inputValue);
      // formData.append("organization_id", organizationId);
      formData.append("patient_id", patientId.patient_id);
      formData.append("interaction_id", lastInteraction);
      formData.append("stored_signal", IsVisitUpdated);
      // formData.append("patient_id", "170b94c0");
      // https://medscribe-server-sand.medscribe.in/api/simmer/v1/accounts/medscribe/chat/230447-a2d-420f-85c7-47bb2c0c8bec85?user_id=ff547fa8-732a-46b2-9c6f-dc532ef33e2b&patient_id=4&doctor_id=4&collection_name=medscribe'

      formData.append("doctor_id", doctorId);
      // formData.append("doctor_id", "31");

      try {
        for (let pair of formData.entries()) {
          console.log(pair[0], pair[1]);
        }

        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };
        let url = new URL(
          `${configJson.CHATBOT_URL}/api/simmer/v1/accounts/${organizationId}/chat/${session_id}`
        );
        url.searchParams.set("lp", "true");
        url = url.toString();

        fetch(url, requestOptions)
          .then((data) => {
            if (data.status !== 200) {
              setBotError(true);
              return Promise.reject(new Error("Fetch failed"));
            }
            return data.json();
          })

          .then((data) => {
            // clearTimeout(loadingTimeout);
            setQueryResponse(data);
            // console.log("querydata", data);
            setLoading(false);
            setClose(true);
            let parsedAnswer;
            try {
              // Attempt to parse the answer as JSON
              parsedAnswer = JSON.parse(data.answer);
              // console.log(parsedAnswer, "parsedAnswer");

              // Check for "C/O" only if the parsed answer is valid JSON

              // Check if "C/O" exists in the parsed JSON
              if (parsedAnswer && parsedAnswer.hasOwnProperty("C/O")) {
                console.log("cotrt");
                setShowOutPatientTemplate(true); // Show the template if "C/O" is found
              } else if (
                parsedAnswer &&
                parsedAnswer.hasOwnProperty("discharge_summary")
              ) {
                setIsDischargeSummary(true); // Hide the template if "C/O" is not found
              } else if (data.funct_name == "diagnosis_rag_engine") {
                setShowOutPatientTemplate(true); // Show the template if organization_id is 15
              } else {
                setShowOutPatientTemplate(false); // Hide the template if neither condition is met
              }
            } catch (err) {
              // If parsing fails, log the error
              console.log("Answer is not in JSON format", err);

              // Handle the fallback condition when the answer is not JSON
              if (data.funct_name == "diagnosis_rag_engine") {
                setShowOutPatientTemplate(true); // Show the template if organization_id is 15
              } else {
                console.log("else fallback");
                setShowOutPatientTemplate(false); // Default to not showing template
              }
            }

            // changelastmessage({
            //   ...data,
            //   // product_name: product_name || activeChip?.product_name || "",
            //   author: "bot",
            //   content: data.answer.replaceAll("\\xa0", " "),
            //   bot_answer_id: data?.conversation_id,
            //   session_id: data?.session_id,
            //   time: data?.answered_at,
            //   map_link: data?.map_link,
            //   audio_link: data?.answer_audio_link,
            //   source: data?.source,
            //   question_audio_link: data?.question_audio_link,
            //   product_name: data?.product_name,
            //   answer_video_url: data?.answer_video_link,
            // });
            // return data; //need to enable this when uncomment image
            resolve("fetch done");
          });
      } catch (err) {
        // setStaticQns('')
        console.log(err);
      }
      // finally {
      //   setStaticQns("");
      //   setInputValue("");
      // }
    });
  }

  // useEffect(() => {
  //   if (staticQns != "") {
  //     sendQuestion();
  //   }

  //   // console.log("QQQQ", staticQns);
  // }, [staticQns]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Save the uploaded image in the state
    }
  };

  // console.log("III", imageFile);

  const displayImage = () => {
    if (!imageFile) return null; // Handle case where no image is selected

    // Handle different image loading scenarios:
    if (imageFile instanceof File) {
      // Assuming you're using FileReader:
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageFile(e.target.result); // Update state with image data URL
      };
      reader.readAsDataURL(imageFile);
    } else if (typeof imageFile === "string") {
      // If imageFile is a URL:
      return <img src={imageFile} alt="" width={50} />;
    } else {
      console.error("Unexpected imageFile format");
      return null; // Handle unexpected format
    }
  };
  const stopRecording = () => {
    setChecked(false);
  };
  const startRecording = () => {
    setChecked(true);
  };
  // console.log("patientId.patient_id", patientId);
  // console.log(staticQns, 'PPP')

  return (
    <div>
      <ChatContainer>
        <ChatFooter className="chatbot-input w-full flex flex-col lmd:flex-row items-center justify-around gap-2 sm:px-4">
          {patId ? (
            <div className="flex items-center gap-2 w-full lmd:w-1/2">
              <div className="cursor-pointer w-[265px] xs:w-2/3 lmd:w-full flex relative -left-3 xs:left-0">
                {!streamdata ? (
                  <Audiorecord
                    patientId={patId}
                    interactionIdFirst={interactionIdFirst}
                    // handleExportImageUpload={handleExportImageUpload}
                  />
                ) : (
                  <AudioStreamer
                    patientId={patId}
                    interactionIdFirst={interactionIdFirst}
                  />
                )}
                {/* <Audiorecord
                  patientId={patId}
                  interactionIdFirst={interactionIdFirst}
                  handleExportImageUpload={handleExportImageUpload}
                /> */}
              </div>
              <button
                className="rounded-full bg-medblue-light text-white px-3 sm:px-6 py-2 sm:py-3 w-fit lmd:w-32 flex items-center gap-2 lmd:hidden relative -left-12 xs:left-0 h-[55px]"
                // style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
                onClick={() => setUploadModal(true)}
              >
                Upload
                <FaPlus />
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="foot-section lmd:relative lmd:-left-8 h-[55px]">
            {Boolean(!botError) && (
              <>
                <div className="chatt-input">
                  <InputGroup>
                    {/* {imageFile && displayImage()} */}
                    <div className="input-section ">
                      <Form.Control
                        placeholder={"Ask your question?"}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />

                      {/* <button
                      type="button"
                      variant="link"
                      className="text-xl mr-1"
                      onClick={() => document.getElementById('imageUpload').click()}
                    >
                      <FaCamera />
                    </button> */}
                      <input
                        type="file"
                        id="imageUpload"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      {role === "doctor" ? (
                        <RecordContainer>
                          <div className="record-group-button">
                            <button
                              type="button"
                              className="start-stop-btn"
                              onClick={
                                ISChecked ? stopRecording : startRecording
                              }
                            >
                              <div className="button-content">
                                <span className="rec-btn-icon">
                                  {ISChecked ? (
                                    <FaStop size={20} />
                                  ) : (
                                    <FaMicrophone size={20} />
                                  )}
                                </span>
                              </div>
                            </button>
                          </div>
                        </RecordContainer>
                      ) : (
                        <MicSection>
                          <div className="pca-mic">
                            <ChatAudioText
                              setVoiceInputText={setVoiceInputText}
                            />
                          </div>
                        </MicSection>
                      )}
                      <InputGroup.Text id="basic-addon2">
                        <Button
                          type="button"
                          variant="link"
                          onClick={() => {
                            setStaticQns("");
                            sendQuestion();
                          }}
                          disabled={!inputValue || botFetch}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="send-btn "
                          >
                            <title>ionicons-v5-q</title>
                            <path
                              d="M16,464,496,256,16,48V208l320,48L16,304Z"
                              fill="#06AEB6"
                            />
                          </svg>
                        </Button>
                      </InputGroup.Text>
                    </div>
                    {/* <ThumbContainer>{thumbs}</ThumbContainer> */}
                  </InputGroup>
                  {/* <div className="camera-container">
                  <button
                    type="button"
                    variant="link"
                    className="text-xl mr-1"
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                  >
                    <FaCamera />
                  </button>
                  <button type="button"><FaVideo size={20}/></button>
                </div> */}
                </div>
              </>
            )}
          </div>

          <button
            className="rounded-full bg-medblue-light text-white p-4 py-3 w-32 h-[55px] items-center gap-2 hidden lmd:flex"
            // style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
            onClick={() => setUploadModal(true)}
          >
            Upload
            <FaPlus />
          </button>
        </ChatFooter>
        <div className={`gap-2 text-sm mt-3 w-full sm:px-4 ${organizationCountry === "India" ? 'grid grid-cols-2 lmd:grid-cols-4 lgl:!grid-cols-5' : 'flex items-center justify-center'}`}>
          {organizationCountry === "India" && (
            <div
              className="p-3 text-center rounded-xl bg-medblue-light text-white cursor-pointer flex items-center justify-center gap-1"
              onClick={() => {
                setStaticQns("Generate Soap notes");
                setShowOutPatientTemplate(false);
                setIsDischargeSummary(false);
                sendQuestion("Generate Soap notes");
              }}
            >
              <span>Generate</span> <span>Soap</span> <span>notes</span>
            </div>
          )}
          <div
            className={`p-3 text-center rounded-xl bg-medblue-light text-white cursor-pointer ${organizationCountry !== "India" ? 'sm:w-1/4' : ''}`}
            onClick={() => {
              setStaticQns("Generate Outpatient Datasheet");
              setShowOutPatientTemplate(true);
              setIsDischargeSummary(false);
              sendQuestion("Generate Outpatient Datasheet");
            }}
          >
            O/P Datasheet
          </div>
          {/* <div
            className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
            onClick={() => {
              setShowBillingTemplate(true);
            }}
          >
         Create Invoice
          </div> */}
          {/* <div
            className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
            onClick={() => {
              // setStaticQns("Generate Outpatient Datasheet");
              setShowOutPatientTemplate(true);
            }}
          >
            Create Invoice
          </div> */}

          {/* <div
            className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
            onClick={() => setStaticQns("Create Prescription")}
          >
            Create Prescription
          </div> */}

          {/* <div
                className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
                onClick={() => {
                  // setStaticQns("Create Assessment")
                  setStaticQns("Discharge Summary")
                  setIsDischargeSummary(false)
                }}
              >
                Discharge Summary
              </div> */}
          {organizationCountry === "India" && (
            <div
              className="p-3 text-center rounded-xl bg-medblue-light text-white cursor-pointer"
              onClick={() => {
                // setStaticQns("Create Assessment")
                setStaticQns("Create Assessment");
                setIsDischargeSummary(false);
                sendQuestion("Create Assessment");
              }}
            >
              Create Assessments
            </div>
          )}
          {organizationCountry === "India" && (
            <button
              className="p-3 text-center rounded-xl bg-medblue-light text-white"
              // style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
              onClick={() => setVitalsModal(true)}
            >
              Vitals
            </button>
          )}
          {streamdata ? (
            <button
              className={`p-3 text-center rounded-xl bg-medblue-light text-white ${organizationCountry !== "India" ? 'sm:w-1/4' : ''}`}
              // style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
              onClick={() => {
                setStreamdata(false);
                sessionStorage.setItem("isStreaming", false);
              }}
            >
              Stream OFF
            </button>
          ) : (
            <button
              className={`p-3 text-center rounded-xl bg-medblue-light text-white ${organizationCountry !== "India" ? 'sm:w-1/4' : ''}`}
              // style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
              onClick={() => {
                setStreamdata(true);
                sessionStorage.setItem("isStreaming", true);
              }}
            >
              Stream ON
            </button>
          )}

          {/* <button
          className="rounded-3xl bg-gray-200 py-2 px-3 w-32"
          // style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
          onClick={() => setShowTempComp(true)}
        >
          Create Invoice
        </button> */}
        </div>
      </ChatContainer>

      {isBot && (
        <div className="p-4 mt-2">
          <div className="flex items-center gap-2">
            <div>
              <Image className="w-6" src={userImg} alt="bot image" />
            </div>
            <div className="bg-[#F6F8FA] rounded-xl w-fit py-2 text-sm px-3 shadow-md">
              {staticQns !== "" ? staticQns : inputValue}
            </div>
          </div>

          {queryResponse && (
            <div>
              {close ? (
                <div>
                  <div className="pb-3">
                    <BotResponse>
                      <div>
                        <Image
                          className={"bot-img"}
                          src={bot}
                          alt="bot image"
                        />
                      </div>

                      <div className="bot-content border-1 border-gray-300 shadow">
                        <button
                          className="float-right mt-3 mr-3"
                          onClick={() => {
                            setClose(false);
                            setIsBot(false);
                            setIsDischargeSummary(false);
                            setStaticQns("");
                            setInputValue("");
                          }}
                        >
                          <FaTimes />
                        </button>
                        <div>
                          {showOutPatientTemplate ? (
                            organizationCountry != "India" ? ( // Check for organization_id
                              <OutPatientTemplateUS
                                patientId={patientId.patient_id}
                                registrationNumber={
                                  patientId.hospital_patient_id
                                }
                                tokenNumber={
                                  patientId?.Interactions[0]?.token_number
                                }
                                date={displayDateTimeUS}
                                drawingTemplateURL={
                                  latestDrawingTemplateContent
                                }
                                patientName={patientName}
                                patientNamewithouspace={patientNamewithouspace}
                                patientAddress={patientId.address1}
                                doctorName={doctorName}
                                salutation={salutation}
                                doctorId={doctorIdDatasheet}
                                tnmcRegNo=""
                                appointmentNo=""
                                gender={patientId.gender}
                                dob={patientId.date_of_birth}
                                maritalStatus="Single"
                                mobileNumber={patientId.phone_number}
                                fetchInteractions={fetchInteractions}
                                audioUrlInteractionId={audioUrlInteractionId}
                                answer={queryResponse.answer} // Pass the initial answer
                                summary={queryResponse.summary} // Pass the initial answer
                                onAnswerChange={handleAnswerChangeUS} // Pass the handler to update answer
                              />
                            ) : (
                              <OutpatientDataSheetGunam
                                patientId={patientId.patient_id}
                                registrationNumber={
                                  patientId.hospital_patient_id
                                }
                                tokenNumber={
                                  patientId?.Interactions[0]?.token_number
                                }
                                date={displayDateTime}
                                patientName={patientName}
                                patientNamewithouspace={patientNamewithouspace}
                                patientAddress={patientId.address1}
                                salutation={salutation}
                                doctorName={doctorName}
                                doctorId={doctorIdDatasheet}
                                tnmcRegNo=""
                                appointmentNo=""
                                ageField={patientId.age}
                                gender={patientId.gender}
                                dob={patientId.date_of_birth}
                                maritalStatus="Single"
                                mobileNumber={patientId.phone_number}
                                fetchInteractions={fetchInteractions}
                                audioUrlInteractionId={audioUrlInteractionId}
                                answer={queryResponse.answer} // Pass the initial answer
                                summary={queryResponse.summary} // Pass the initial answer
                                onAnswerChange={handleAnswerChange} // Pass the handler to update answer
                              />
                            )
                          ) : (
                            <div className="p-4">
                              <MarkdownDisplay
                                markdownContent={queryResponse?.answer}
                              />
                            </div>
                          )}
                        </div>

                        {/* <div>
    {staticQns === "Out Patient Datasheet" ? (
  <OutPatientTemplate answer={queryResponse?.answer} />
) : (
  <MarkdownDisplay markdownContent={queryResponse?.answer} />
)}
</div> */}
                      </div>
                    </BotResponse>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {loading && (
            <div className="flex items-start gap-3 mt-2">
              <div>
                <Image className="w-6" src={bot} alt="bot image" />
              </div>
              <div className="w-full h-[50vh] flex items-center rounded-2xl justify-center border-1 border-gray-300 shadow ">
                <Spin size="large" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ChatbotQuery;
