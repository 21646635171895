import styled from "styled-components";
export const ImageContainer = styled.div`
  .image-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // margin-top: 20px;
    padding: 10px 0px;
  }
  .side-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .image-text {
    .heading-text {
      margin: 0px 5px;
      padding: 0px;
      text-align: center;
    }
    .image-file {
   
      height: auto;
      margin: 0px auto;
      @media (max-width: 767px) {
        width: 200px;
      }
    }
  }
`;

export const SidebarContainer = styled.div`
  height: calc(100vh - 14vh);
  .search-items {
    .input-text {
      position: relative;
      margin: 25px 10px;
      border: 1px solid #0000004d;
      border-radius: 30px;
      width: 93%;
      background-color: #d9d9d9;
      padding: 6px 17px;
    }
    .search-icon-container {
      position: absolute;
      right: 23px;
      top: 129px;
      .search-input-icon {
        width: 15px;
        height: 15px;
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .settings {
    position: absolute;
    bottom: -25px;
    text-align: left;
    width: 100%;
    .side-btn {
      border: none;
      color: black;
      height: 55px;
      text-align: left;
      padding: 5px 20px 5px 20px;
      width: 100%;
      // border-radius: 0px 0px 25px 0px;
      display: flex;
      align-items: center;
      .btn-image {
        width: 23px;
        height: 23px;
        margin-right: 5px;
      }
    }
  }
  .side-arrow-container {
    position: absolute;
    bottom: 20px;
    right: -23px;
    border-radius: 25px;
    .side-arrow-btn {
      color: #fff;
      background-image: linear-gradient(to right, #23aad2, #23d3ae);
      .btn-arrow-image {
        width: 23px;
        height: 23px;
        padding: 20px;
        border-radius: 20px;
        margin-right: 5px;
      }
    }
  }
`;

export const Buttonstyle = styled.div`
  .side-btn {
    display: flex;
    border: none;
    color: black;
    height: 55px;
    text-align: left;
    padding: 5px 20px 5px 20px;
    width: 100%;
    gap: 2px;
    align-items: center;
    .btn-image {
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }
  }
  .side-btn.active {
    color: #fff;
    background-image: linear-gradient(to right, #23aad2, #23d3ae);
    padding: 5px 20px 5px 20px;
  }
`;

export const SettingTop = styled.div`
  .go-back-btn {
    color: #fff;
    background-image: linear-gradient(to right, #23aad2, #23d3ae);
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    border: 0px;
    margin-left: 10px;
    .back-btn-icon {
      margin-right: 5px;
    }
  }
  .setting-text {
    text-align: left;
    margin-left: 10px;
    font-size: 25px;
    margin-top: 10px;
    color: black;
  }
  .mybusiness {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    .mybusiness-text {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      font-size: 12px;
      width: 100%;

      .mybusiness-line {
        border-top: 1px solid black;
        text-align: center;
        width: 65%;
        margin-left: 5px;
        margin-top: 5px;
      }
    }
  }
`;

export const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: calc(100vh - 15vh);
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #23aad2, #23d3ae);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const ScrollButton = styled.button`
  position: fixed;
  right: 20px;
  bottom: ${(props) => (props.isTop ? "60px" : "20px")};
  background-color: #1c1b1b;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  &:hover {
    background-color: #5b5b5b;
  }
`;
export const InteractionPatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;
export const SideInteractionPatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 2px;
  height: calc(100vh - 60vh); //check
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const TokenNumberContainer = styled.div`
  width: 30px;
  height: 30px;
  background-color: mistyrose;
  padding: 2px;
  margin: 2px;
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
`;

export const SideListInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .side-input-date {
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  svg {
    cursor: pointer;
  }
`;
