import styled from "styled-components";

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  // position: fixed;
  // top: 65px;
  background-color: white;
  z-index: 99;
`;
export const ChatFooter = styled.div`
  // padding: 10px;
  // width: 75%;
  margin: auto;
  //padding: 10px;
  /* border-top: 1px solid ${(props) => props.theme.borderColor}; */
  // width: 100%;
  //margin: auto;
  //background-color: ${(props) => props.theme.topbarfooterBg};
  // @media (max-width: 767px) {
  //   position: fixed;
  //   width: 100%;
  //   bottom: 0;
  // }
  input {
    border: none;
    border-radius: 3px;
    border-color: transparent; /* Optional: Remove border color */

    font-size: 1em;
  }
  input:focus {
    border: none; /* Remove the default outline */
  }

  .btn-link {
    fill: ${(props) => props.theme.primaryColor} !important;
    padding: 0;
  }
  .pre-content {
    font-size: 14px;
  }
  .pre-chip {
    // color: ${(props) => props.theme.primaryTextColor};
    // background-color: ${(props) => props.theme.primaryColor};
    padding: 1px 6px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 6px;
    display: inline-block;
  }
  .foot-section {
    display: flex;
    align-items: center;
    // max-width: 830px;
    width: 100%;
    margin: auto;
    // @media (max-width: 767px) {
    //   width: 100%;
    // }
    .camera-container {
      text-align: end;
      margin-right: 10px;
      padding: 10px 10px 0px 10px;
    }
  }
  .chatt-input {
    flex: 1;
    .input-group {
      display: flex;
      flex-direction: column;
      min-height: 50px;
      max-height: 170px;
      padding: 4px;
      overflow-y: auto;
      flex-wrap: nowrap;
      border-radius: 32px;
      border: 1px solid #23aad2;
      // background-color: ${(props) => props.theme.chatInputBg};
      background-color: #eeeeee;
      /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1); */
      max-width: 100%;
      width: 100%;
      margin: auto;
      @media (max-width: 767px) {
        min-height: 50px;
        /* max-height: 50px; */
      }
      .input-section {
        display: flex;
        align-items: center;
      }
    }
    .input-group-text {
      border: none;
      padding: 0;
      margin-right: 5px;
      border-radius: 50px;
      background-color: #eeeeee;
      &:hover {
        background-color: ${(props) => props.theme.hoverColor};
        border-radius: 50px;
      }
      .send-btn {
        width: 45px;
        height: 45px;
        padding: 10px;
        color: #25b8bf;
      }
    }
    .form-control {
      //   background-color: ${(props) => props.theme.chatInputBg};
      //   color: ${(props) => props.theme.themeTextColor};
      padding: 7px 6px 7px 14px;
      background-color: #eeeeee;
      @media (max-width: 767px) {
        padding: 4px 6px 4px 14px;
      }
      &::placeholder {
        color: ${(props) => props.theme.textMuteColor};
      }
    }
  }
  .footer-preview {
    padding-bottom: 5px;
    width: 95%;
    margin: auto;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const MicSection = styled.div`
  /* width: 40px;
 height: 40px; */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #fff;
    border-radius: 50px;
  }
  .pca-mic {
    position: relative;
    background-color: #eeeeee;
    .mike {
      border-radius: 50px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: #938f8f47; */
      cursor: pointer;
      .btn {
        padding: 0;
        margin: 0;
        svg {
          color: black;
          margin-bottom: 3px;
        }
      }
      .pulse-ring {
        content: "";
        width: 35px;
        height: 35px;
        background: transparent;
        border: 2px solid black;
        border-radius: 50%;
        position: absolute;
        top: -6px;
        left: -9px;
        animation: pulsate infinite 1.5s;
      }
      @keyframes pulsate {
        0% {
          -webkit-transform: scale(1, 1);
          opacity: 1;
        }
        100% {
          -webkit-transform: scale(1.3, 1.3);
          opacity: 0;
        }
      }
    }
    .mic-active {
      background-color: #fff;
    }
  }
`;
export const BotResponse = styled.div`
  color: ${(props) => props.theme.aiResponseTxt};
  border-bottom: none;
  flex: 1;
  align-self: flex-start;
  display: flex;
  /* padding: 5px; */
  padding: 8px 0;
  max-width: 100%;
  width: 100%;
  gap: 20px;
  @media (max-width: 767px) {
    max-width: 100%;
    gap: 10px;
  }
  .bot-msg {
    margin-bottom: 0;
    font-family: "Helvetica Neue", sans-serif !important;
  }
  .bot-img {
    width: 24px;
    border-radius: 50%;
  }
  .bot-img-animation {
    width: 24px;
    /* margin-right: 3px; */
    border-radius: 50%;
    -webkit-animation: spin-zoom 4s linear infinite;
    -moz-animation: spin-zoom 4s linear infinite;
    animation: spin-zoom 4s linear infinite;
    @keyframes spin-zoom {
      0% {
        -webkit-transform: rotate(0deg) scale(1, 1);
        transform: rotate(0deg) scale(1, 1);
      }
      25% {
        -webkit-transform: rotate(90deg) scale(1.1, 1.1);
        transform: rotate(90deg) scale(1.1, 1.1);
      }
      50% {
        -webkit-transform: rotate(180deg) scale(1, 1);
        transform: rotate(180deg) scale(1, 1);
      }
      75% {
        -webkit-transform: rotate(270deg) scale(1.1, 1.1);
        transform: rotate(270deg) scale(1.1, 1.1);
      }
      100% {
        -webkit-transform: rotate(360deg) scale(1, 1);
        transform: rotate(360deg) scale(1, 1);
      }
    }
  }
  .bot-content {
    display: inline-block;
    /* word-break: break-word; */
    overflow-wrap: break-word;
    max-width: 100%;
    font-size: 16px;
    padding-top: 2px;
    /* white-space: pre-line; */
    border-radius: 15px;
    width: 100%;
    /* padding: 10px; */
    /* background-color: ${(props) => props.theme.aiResponseBg}; */
    color: ${(props) => props.theme.themeTextColor};
    line-height: 1.6rem;
    letter-spacing: normal;
    @media (max-width: 767px) {
      font-size: 15px;
    }
    /* &:hover {
 background-color: ${(props) => props.theme.aiResponseHoverBg};
 color: ${(props) => props.theme.aiResponseHoverTxt};
 } */
  }
  .react-loading-skeleton {
    /* background-color: ${(props) => props.theme.themeColor}; */
    --base-color: ${(props) => props.theme.hoverColor};
  }
`;

export const UserResponse = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  .user-msg-section {
    display: flex;
    /* align-items: center; */
    /* padding: 5px; */
    padding: 8px 0;
    gap: 20px;
    color: ${(props) => props.theme.themeTextColor};
    max-width: 100%;
    /* justify-content: flex-end; */
    @media (max-width: 767px) {
      max-width: 100%;
      gap: 20px;
    }
  }
  .user-txt {
    border-radius: 10px 10px 0px 10px;
    padding-top: 2px;
    /* background-color: ${(props) => props.theme.userResponseBg}; */
    /* padding: 10px; */
    /* &:hover {
 background-color: ${(props) => props.theme.userResponseHoverBg};
 color: ${(props) => props.theme.userResponseHoverTxt};
 } */
    color: ${(props) => props.theme.themeTextColor};
    line-height: 1.6rem;
    letter-spacing: normal;
    img {
      max-width: 400px;
      width: 100%;
      border-radius: 15px;
      @media (max-width: 767px) {
        max-width: 300px;
      }
    }
  }
  .user-audiotxt {
    border-radius: 10px 10px 0px 10px;
    padding-top: 5px;
    /* background-color: ${(props) => props.theme.userResponseBg}; */
    /* padding: 10px; */
    /* &:hover {
 background-color: ${(props) => props.theme.userResponseHoverBg};
 color: ${(props) => props.theme.userResponseHoverTxt};
 } */
    color: ${(props) => props.theme.themeTextColor};
    line-height: 1.6rem;
    letter-spacing: normal;
    img {
      max-width: 400px;
      width: 100%;
      border-radius: 15px;
      @media (max-width: 767px) {
        max-width: 300px;
      }
    }
  }
  .user-img {
    width: 30px;
    height: 30px;
    /* margin-right: 3px; */
    /* margin-top: 5px; */
    border-radius: 50%;
  }
  .user-info {
    white-space: pre-line;
    word-break: break-word;
    overflow-wrap: break-word;
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .ufnn-color {
    background-color: #ee120b;
  }
  .user-fnn {
    width: 32px;
    height: 32px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    margin-right: 14px;
  }
`;
export const ChatImage = styled.div`
  .ant-popover-content {
    display: flex;
  }
`;
export const MarkdownWrapper = styled.div`
  .markdown-container {
    /* img {
      width: 100%;
      max-width: 300px;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.3);
      }
    } */
    .bot-image-container {
      .product-img {
        width: 100%;
        max-width: 350px;
        margin-top: 5px;
        margin-bottom: 10px;
        border-radius: 15px;
      }
    }
    strong {
      color: ${(props) => props.theme.primaryColor};
    }
    @media (max-width: 767px) {
      .ai-table {
        max-width: 300px;
        overflow-x: auto;
      }
    }
  }
  /* Example CSS for styling the table */
  .markdown-container table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
    margin-top: 0;
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    tr:nth-child(2n) {
      background-color: #00000008;
    }
    tr {
      &:hover {
        background-color: #00000013;
      }
    }
  }

  .markdown-container th,
  .markdown-container td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .markdown-container th {
    // background-color: ${(props) => props.theme.tableHeaderColor};
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryTextColor};
    font-weight: 500;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.primaryColor};
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .tag {
    background-color: ${(props) => props.theme.buttonColor};
    color: ${(props) => props.theme.buttonTextColor};
    border: 1px solid ${(props) => props.theme.buttonColor};
    &:hover {
      background-color: ${(props) => props.theme.buttonHoverColor};
      border: 1px solid ${(props) => props.theme.buttonHoverColor};
      color: ${(props) => props.theme.buttonHoverTextColor};
      cursor: pointer;
    }
  }
  .sourceLinkHead {
    color: ${(props) => props.theme.themeTextColor} !important;
    margin-top: 10px;
    font-size: 16px;
  }
`;
export const QueryResponse = styled.div`
  background-color: #e5e7eb;
  border-radius: 8px;
  padding: 30px 50px 30px 50px;
  width: 90%;
  margin: 50px 50px 10px 50px;

  .theme-button {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 0px 10px;
    border-radius: 25px;
    margin: 0px;
    height: 34px;
  }
  @media (max-width: 1200px) {
    margin: 20px;
    padding: 20px;
  }

  @media (max-width: 800px) {
    margin: 20px;
    padding: 20px;
  }

  @media (max-width: 480px) {
    margin: 0px;
    padding: 0px;
  }
`;
